import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import { useTranslation } from 'react-i18next';


export default function BillingAddress(props: any) {
    let billingDetails = props?.billingDetails;
    const { t } = useTranslation();
    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">
                <Typography className='conftionTitle'>{t('Billing Address')}</Typography>
            </Box>
            <hr className='conftion_hr' />
            <Box className="BillingDetails">
                <Box className="Booking_SummaryContent">
                    <CardLabel title={`${t('Address')}: `} subTitle={billingDetails?.streetAddress} />
                    <CardLabel title={`${t('Province')}/${t('State')}: `} subTitle={billingDetails?.province} />
                </Box>

                <Box className="Booking_SummaryContent">
                    <CardLabel title={` ${t('Country')}: `} subTitle={billingDetails?.country} />
                    <CardLabel title={`${t('Postal')}/ ${t('Zip Code')}: `} subTitle={billingDetails?.postalCode} />
                </Box>
                <Box className="Booking_SummaryContent">
                    <CardLabel title={`${t('City')}: `} subTitle={billingDetails?.city} />
                </Box>
            </Box>
        </Box>
    )
}