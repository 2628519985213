import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const bookingData = createAction<any[]>(`bookingResult/${ACTIONS.BOOKING_RESULT}`);
const flowFrom = createAction<any[]>(`bookingResult/${ACTIONS.FLOWFROM}`);
const setSegment = createAction<any[]>(`bookingResult/${ACTIONS.SEGMENTDATA}`);

export const updateBookingData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(bookingData(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};

export const updateFlowFrom: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(flowFrom(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};

export const setSegmentData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setSegment(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};