import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { calculateTotalForPassenger } from '../../utils/util';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { parseAmount } from '../../utils/constant';
import { useSelector } from 'react-redux';
// @ts-ignore
import iconhanddollar from '../../assets/icons/icon-hand-dollar.svg';

const UpliftBookingComponent = ({ itineraryFare, tripInfoRequest }: any) => {
    const { t } = useTranslation();
    const UPLIFT_CONFIG = process.env.UPLIFT_CONFIG || {};
    const { UPLIFT_LOGO } = UPLIFT_CONFIG as { [key: string]: string };
    const language = useSelector((state: any) => state.languageChange.language);

    useEffect(() => {
        if (tripInfoRequest && window.Uplift?.Payments) {
            window.Uplift.Payments.load(tripInfoRequest);
        }
    }, [tripInfoRequest]);

    const totalFlightPrice = itineraryFare?.paxFares?.reduce((acc: number, current: any) => {
        return acc + calculateTotalForPassenger(current);
    }, 0);

    return (
        // <Box className="Booking_PayInfo priceSummary_uplift">
        <Box
            className='Booking_FlightDetails Booking_FlightUpliftBox'  >
            <Box
                className="Booking_flightHeader"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // Adds spacing between image and text
                }}
            >
                <img src={iconhanddollar} className="iconhanddollar" alt="Icon" />
                <Typography
                    variant="h6"
                    className="Booking_flightTitle"
                >
                    {t("Flexible Payment Option")}
                </Typography>
            </Box>
            <Box p={2}>
                {/* Pay in Full Option */}
                {totalFlightPrice > 0 && (
                    <Box
                        className="Booking_payInFull"
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: "#2c3e50",
                                fontWeight: "normal",
                            }}
                        >
                            {t("Pay in Full amount of")}{' '}
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#2c3e50",
                                }}
                            >
                                ${parseAmount(totalFlightPrice)}
                            </span>
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#2c3e50", marginTop: "8px" }}>
                            {t("Call Us")} 1-866-573-3824
                        </Typography>
                    </Box>
                )}

                {/* Pay Monthly Option */}
                <Box
                    className={`Booking_payTitle payUpliftTitle ${language === "fr" ? "payUpliftTitleFr" : ""
                        }`}
                    sx={{
                        padding: "16px",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                        {t("Pay Monthly from")}
                        {totalFlightPrice > 0 && (
                            <>
                                <Button
                                    className="displayFlex"
                                    data-up-price-value={parseAmount(totalFlightPrice * 100)}
                                    data-up-price-type="total"
                                    data-up-comparison-type=""
                                    data-up-price-model="total"
                                    data-up-taxes-included="true"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        textTransform: "none",
                                    }}
                                >
                                    <span>
                                        <span className="up-from-currency">$</span>
                                        <span className="mb-1" data-up-from-currency-unit-major=""></span>
                                        /{t("mo")}
                                    </span>
                                    <InfoIcon sx={{ marginLeft: "4px" }} />
                                </Button>
                            </>
                        )}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "8px",
                        }}
                    >
                        <Typography variant="body2">{t("Powered by")}</Typography>
                        <img
                            alt="uplift logo"
                            className="uplift-logo-2"
                            src={UPLIFT_LOGO}
                            style={{ marginLeft: "8px" }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>


    );
};

export default UpliftBookingComponent;
