import React from 'react';
import { Box, Typography, Radio, Button, List, ListItem, ListItemAvatar, ListItemText, Drawer, Skeleton } from '@mui/material';
import Dollars from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from "react-slick";

export const BrandedFareLoadingScreen = (props: any) => {
    const matches = useMediaQuery('(min-width:600px)');

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,

    };

    const CardSkeleton = () => (
        <Box className="fareTypeCrads" >
            <Box className="fareType_Top">
                <Box className="fareTypePrice">
                    <Skeleton variant="text" width="70%" />
                    <Skeleton variant="text" width="50%" />
                </Box>
                <Skeleton variant="circular" width={24} height={24} />
            </Box>
            <Box className="fareType_Content">
                <Box className="fareType_top">
                    <Skeleton variant="text" width="40%" />
                    <Skeleton variant="text" width="30%" />
                </Box>
                {Array.from({ length: 3 }).map((item1, subIndex) => (
                    <React.Fragment key={subIndex}>
                        <Skeleton variant="text" width="60%" sx={{ marginBottom: '8px' }} />
                        <List className='fareType_IconWithTitle' sx={{ marginBottom: '8px' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Skeleton variant="circular" width={24} height={24} />
                                </ListItemAvatar>
                                <Skeleton variant="text" width="80%" />
                            </ListItem>
                        </List>
                    </React.Fragment>
                ))}
            </Box>
            <Box className="fareType_btnSection">
                <Skeleton variant="rectangular" width="100%" height={36} />
            </Box>
        </Box>
    );

    return (
        <>
            <Drawer open={true} anchor={"right"}>
                <Box className="fareTypeSection" sx={{ width: matches ? '50vw' : '100vw', overflowX: 'hidden' }}>
                    <Box className="inOut_top">
                        <Box className="inOut_Inner">
                            <Skeleton variant="circular" width={40} height={40} />
                            <Box sx={{ flexGrow: 1, marginLeft: '16px' }}>
                                <Skeleton variant="text" width="60%" />
                                <Skeleton variant="text" width="40%" />
                            </Box>
                            <Skeleton variant="rectangular" width={24} height={24} />
                        </Box>
                    </Box>
                    <Box className="inOutContent">
                        <Skeleton variant="text" width="50%" />
                        <Skeleton variant="text" width="80%" />
                    </Box>
                    <Box className="inOutSlider">
                        <Slider {...settings}>
                                    <CardSkeleton  />
                                    <CardSkeleton  />
                                    <CardSkeleton  />
                                    <CardSkeleton  />
                                    <CardSkeleton  />

                        </Slider>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};
