import React, { ElementType, SyntheticEvent } from 'react';
import { Grid, Autocomplete, Typography, TextField, IconButton, createFilterOptions } from '@mui/material';
import { DEFAULT_FUNCTION } from '../../../utils/constant';
import { GridInterface } from "../../../interfaces/commonInterface";

interface AutoCompleteInterface {
    className?: string;
    disablePortal?: boolean;
    id?: string;
    autoSuggestOptions?: Array<any>;
    onChangeMethod: Function;
    onChangeKeyMethod?: Function;
    label?: string;
    typographyClassName?: string;
    inputValue?: string;
    onInputChangeMethod: Function;
    onInputChangeKeyMethod?: Function;
    iconClass?: string;
    gridOptions: GridInterface & { component?: ElementType<any> };
    Icon?: ElementType<any>;
}

const AutocompleteComponent = ({
    id = "",
    className = "",
    disablePortal = true,
    autoSuggestOptions = [],
    onChangeMethod = DEFAULT_FUNCTION,
    onChangeKeyMethod = DEFAULT_FUNCTION,
    label = "",
    typographyClassName = "",
    inputValue = "",
    onInputChangeMethod = DEFAULT_FUNCTION,
    onInputChangeKeyMethod = DEFAULT_FUNCTION,
    iconClass = "",
    gridOptions = {},
    Icon = null
}: AutoCompleteInterface) => {
    const filter = createFilterOptions({
        ignoreCase: true,
        stringify: (option: any) => `${option.label} ${option.customAbbreviation}`,
    });
    return (
        <Grid {...gridOptions}>
            <Typography className={typographyClassName}>{label}</Typography>
            <Autocomplete
                value={inputValue}
                filterOptions={(options, state) => 
                    filter(options, state)
                }   
                className={className}
                disablePortal={disablePortal}
                id={id}
                options={autoSuggestOptions}
                inputValue={inputValue}
                disableClearable={!inputValue}
                onChange={(e: SyntheticEvent<any>, data: string) =>
                    onChangeMethod(e, data, onChangeKeyMethod)
                }
                onInputChange={(e: SyntheticEvent, inputValue: string): void => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    onInputChangeMethod(e, inputValue, onInputChangeKeyMethod)
                }
                }
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        placeholder={label}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <IconButton>
                                    {Icon && <Icon className={iconClass} />}
                                </IconButton>
                            ),
                        }}
                    />
                )}
                renderOption={(props: any, option: any) => (
                    <li {...props}> 
                        <div>
                            <Typography variant="body1">
                                {option.label}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {option.customAbbreviation}
                            </Typography>
                        </div>
                    </li>
                )}
            />
        </Grid>
    );
};


export default AutocompleteComponent;