export const englishErrorMessages = {
    "Please select valid Origin / Destination.": "Please select valid Origin / Destination.",
    "Please select valid dates": "Please select valid dates.",
    "Please select valid age": "Please select valid age.",
    "Please select number of infants": "Please select number of infants less than or equal to number of adult.",
    "price is not available": "We’re sorry, but this fare is currently unavailable for booking. Please try selecting a different fare. You will be redirected to Search Page. Please try again.",
    "Technical error occur. Please contact us to make this booking": "Technical error occur. Please contact us to make this booking.",
    "Please fill all the required fields": "Please fill all the required fields",
    "Please fill out required information in SearchBox section": "Please fill out required information in SearchBox section. ",
    "Please fill out your first and last names along with any other required information in the Traveller section before selecting Pay Monthly": "Please fill out your first and last names along with any other required information in the Traveller section before selecting Pay Monthly. ",
    "No search result found for your search. Please change some parameters and try again": "No search result found for your search. Please change some parameters and try again. ",
    "A technical error prevents us from booking": "A technical error prevents us from booking.",
    "There is no response from the pricing API. You will be redirected to the search page. Please try again.": "There is no response from the pricing API. You will be redirected to the search page. Please try again.",
    "Please fill all required fields for Uplift Payment": "Please fill all required fields for Uplift Payment.",
    "Please select a valid city": "Please select a valid city",
    "Invalid card type": "Invalid card type",
    "Only Visa, MasterCard, and American Express are accepted": "Only Visa, MasterCard, and American Express are accepted.",
    "Please Enter Valid Promo code.": "Please Enter Valid Promo code.",
    "You have an insufficient balance": "You have an insufficient balance.",
    "Branded Fares Error": "Branded Fares Error",
    "No Search Result Found": "No Search Result Found"
}