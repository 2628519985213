import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import { INSURANCESTR } from '../../../utils/constant';
import { useTranslation } from 'react-i18next';

export default function BillingDetails(props: any) {
    const { t } = useTranslation();
    let cardData = props?.paymentData?.[0]?.creditCard;
    let insuranceStatusText = props?.insuranceStatusText;
    let insuranceTotal = props?.insuranceTotal;
    let PaymentMethod = props?.paymentMode;
    let insuranceSubTitle = '';
    let insuranceTitle = '';

    if(insuranceStatusText === INSURANCESTR.comConfirmed)
    {
        insuranceTitle = INSURANCESTR.BillingInsuranceTotalConfirmed;
        insuranceSubTitle = `  CA ${insuranceTotal}`;
    }else if(insuranceStatusText === INSURANCESTR.comDecline){
        insuranceTitle = INSURANCESTR.BillingInsuranceTotalDeclined;
        insuranceSubTitle = ` ${insuranceStatusText}`;
    }

    function maskCreditCardNumber(cardNumber:any) {
        // Ensure the input is a string
        cardNumber = cardNumber.toString();
        
        // Get the first 4 and last 4 digits
        const firstFour = cardNumber.slice(0, 4);
        const lastFour = cardNumber.slice(-4);
        
        // Calculate the number of '*' to be placed in between
        const maskedSectionLength = cardNumber.length - 8;
        const maskedSection = '*'.repeat(maskedSectionLength);
        
        // Concatenate the parts and return
        return firstFour + maskedSection + lastFour;
    }
    
    const cardNumber = cardData?.number;
    const maskedCardNumber = cardNumber && maskCreditCardNumber(cardNumber);
    
    

    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">
                <Typography className='conftionTitle'>{t('Billing Details')}</Typography>
            </Box>
            <hr className='conftion_hr' />
            {cardData &&
                <Box className="BillingDetails">
                    <Box className="Booking_SummaryContent">
                        <CardLabel title={`${t('Credit Card Holder')}:`} subTitle={` ${cardData?.nameOnCard}`} />
                        <CardLabel title={`${t('Card Type')}:`} subTitle={' Visa Card'} />
                    </Box>

                    <Box className="Booking_SummaryContent">
                        <CardLabel title={` ${t('Credit Card Number')}:`} subTitle={` ${maskedCardNumber}`} />
                        <CardLabel title={` ${t('Payment Total')}:`} subTitle={`  CA ${props?.totalAmount}`} />
                    </Box>
                    <Box className="Booking_SummaryContent">
                        <CardLabel title={`${t('Expiry Date')}:`} subTitle={` ${cardData?.expiryMonth}/${cardData?.expiryYear}`} />
                        <CardLabel title={` ${t('Payment Method')}: `}  subTitle={` ${PaymentMethod}`}/>
                    </Box>
                    <Box className="Booking_SummaryContent">
                        <CardLabel title={t(insuranceTitle)} subTitle={t(insuranceSubTitle)} />
                    </Box>
                </Box>
            }
        </Box>
    )
}