import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
// @ts-ignore
import logoRedtag from '../../../assets/icons/logo-redtag.svg';
import { INSURANCESTR } from '../../../utils/constant';
import { formatDate, formatTime } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import { Language } from '@mui/icons-material';
interface StatusDetail {
    code: string;
    description: string;
    status: string;
  }
interface ThankYouSectionInterface {
    recordLocator: string;
    statusDetail:  StatusDetail;
    insuranceStatusText: string;
    allData: any;
    language: string;
}

export default function ThankYouSection(props: ThankYouSectionInterface) {
    const { t } = useTranslation();
    const {recordLocator = '', insuranceStatusText = '', allData={}} = props;
    let insuranceMsg = '';
    if (insuranceStatusText == INSURANCESTR.comConfirmed) { // you have selected insurance
        insuranceMsg = INSURANCESTR.comEmailYourTravelInsurance;
    } else if (insuranceStatusText == INSURANCESTR.comPending) { // you have selected insurance but got error
        insuranceMsg = INSURANCESTR.comEmailTravelInsurancePending
    } else { // you have declined insurance
        insuranceMsg = INSURANCESTR.comEmailYourDeclinedTravelIns
    }

    return (
        <Box className="conftion__wrapper thankYouSection">
            <Grid>
                <Typography className='thankYou__date' component={'span'}>{formatDate(allData?.bookingDate, props?.language)} &nbsp;</Typography>
                {allData?.bookingDate && <Typography className='thankYou__date' component={'span'}>{formatTime(allData?.bookingDate)}</Typography>}
                <hr className='conftion_hr' />
                <Box className="thankYou__Details">
                    {recordLocator &&
                        <Box>
                            <Typography className='thankYouDet__CodeTitle' component={'span'}> {t('Booking Code')} : </Typography>
                            <Typography className='thankYouDet__Codes' component={'span'}>  {t(recordLocator)} </Typography>
                        </Box>
                    }
                    {props?.statusDetail?.description &&
                        <Button className='thankYou_btnStatus'>{props?.statusDetail?.description}</Button>
                    }
                </Box>
            </Grid>
            <Box className="thankYou_body" sx={{ '--logo-redtag': `url(${logoRedtag})` }} >

                <Typography className='thankYou_title' >{t('Thank you for booking with')} redtag.ca!</Typography>
                <Typography className='thankYou_SubTitle'> {t('Your flight booking is currently')} <Typography className='thankYou_SubStatus' component={'span'}>{t('processing')}. </Typography> </Typography>
                <Typography className='thankYou_SubTitle'> {t(insuranceMsg)}</Typography>
                {/* <Typography className='thankYou_SubTitle'> *You have declined travel insurance, <Typography className='thankYou_SubClick' component={'span'}>click here</Typography> to add.</Typography> */}
            </Box>

            <Box className="conftion__infoTag">
                <Box className="conftion_infoheader">
                    <InfoIcon />
                    <Typography className='conftion_infoMainTitle'>  {t('Important Information')}</Typography>
                </Box>
                <Typography className='conftion_infoTitle'>
                    {t('Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.')}
                </Typography>
                <Typography className='conftion_infoTitle'>
                    {t('Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).')}                </Typography>
                <Typography className='conftion_infoTitle'>
                    {t('Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.')}
                </Typography>
            </Box>
        </Box>
    )
}