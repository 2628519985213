import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface BookingResultState {
    booking: {};
    flowFrom: string;
    segment:[]
}

const initialState: BookingResultState = {
    booking: {},
    flowFrom: "booking",
    segment:[]
}

const BookingResult = createSlice({
    name: "bookingResult",
    initialState,
    reducers: {
        [`${ACTIONS.BOOKING_RESULT}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, booking: action.payload}
        },
        [`${ACTIONS.FLOWFROM}`]: (state, action: PayloadAction<any>) => {
            return {...state, flowFrom: action.payload}
        },
        [`${ACTIONS.SEGMENTDATA}`]: (state, action: PayloadAction<any>) => {
            return {...state, segment: action.payload}
        }
    }
});

export const { BOOKING_RESULT, FLOWFROM } = BookingResult.actions;
export default BookingResult.reducer;
