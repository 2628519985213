import React, { useState,useEffect } from 'react';
import { Container, Grid, Paper, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// @ts-ignore 
import iconDollar from '../../../assets/icons/iconDollar.svg';
import { TRIPDIRECTION , INSURANCEPLANCODE, INSURANCESTR, BookingFrom, PASSENGERTYPE, parseAmount} from '../../../utils/constant';
import { calculateTotalForPassenger } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import WhyBookWithUs from './WhyBookWithUs';

interface PriceSummaryComponentProps {
  itineraryFare: any; 
  insuranceTotal?: any;
  insuranceBreakdown?: any;
  giftCardBreakdown?:any;
  priceSummary?:any;
  componentCalledFrom?: string;
  isBookingPage?: boolean;
}


const PriceSummaryComponent: React.FC<PriceSummaryComponentProps> = ({ itineraryFare, insuranceTotal, insuranceBreakdown,giftCardBreakdown, priceSummary,componentCalledFrom, isBookingPage }) => {
  const { t } = useTranslation();
  const returnTaxes = (passengerFare: any) => {
      const { bookingFee, ticketingFee, passengerDetails, taxes, fareBreakDown} = passengerFare;
      const bFee  = bookingFee?.amount || fareBreakDown?.bookingFee?.amount || 0;
      const tFee  = ticketingFee?.amount || fareBreakDown?.ticketingFee?.amount || 0;
      const taxesAndFees = passengerFare.totalTaxes.amount + bFee + tFee;
      return taxesAndFees;
  }

  const isValidArray = (arr: any) => Array.isArray(arr) ? arr : [];
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  // const giftCardTotal = (giftCardBreakdown ?? []).reduce((acc: number, giftCard: any) => acc + giftCard.charge, 0);
  const giftCardTotal = isValidArray(giftCardBreakdown).reduce((acc: number, giftCard: any) => acc + giftCard.charge, 0);

  const displayCurrency = '$';

  // let totalFlightPrice = itineraryFare?.paxFares?.reduce((acc: number, current: any) => {
  //   return acc + calculateTotalForPassenger(current);
  // }, 0);

  let totalFlightPrice = isValidArray(itineraryFare?.paxFares).reduce((acc: number, current: any) => acc + calculateTotalForPassenger(current), 0);


  const itineryTotalFlightPrice = totalFlightPrice;
  if(giftCardTotal > 0){
    totalFlightPrice = Number(totalFlightPrice) - Number(giftCardTotal);
  }
  
  let finalTotalPrice = 0;
  if(insuranceTotal && Number(insuranceTotal) > 0){
    finalTotalPrice = Number(totalFlightPrice) + Number(insuranceTotal);
  }

  
  const priceSummaryData = {
    itineryTotal: itineryTotalFlightPrice,
    giftCardTotal:giftCardTotal,
    finalAmount:totalFlightPrice
  }
  const handleFlightPriceAcc =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };


  useEffect(() => {
    if(componentCalledFrom!='confirmation'){
      priceSummary(priceSummaryData);
    }
  }, [giftCardTotal]);

  return (
    <div className='BookingPriceSummary_Wrapper'>
      <Box className='Booking_PriceSummary'>
        <Box className='Booking_PriceHeader'>
          <img src={iconDollar} className='Icon_flight' />
          <Typography variant="h6" className='Booking_PriceTitle'>
            {t('Price Summary')}
          </Typography>
        </Box>

        {/* Display all passenger fares sequentially */}
        <React.Fragment>
          <Accordion  expanded={expanded === 'panel1'} onChange={handleFlightPriceAcc('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel1-content"}
              id={"panel1-header"}
            >
              <Box className="Booking_FlightPrce">
              <Box>
                <Typography variant="body1" className='booking_AccoHeader'>{t('Flight Price')}</Typography>
              </Box>
              <Box>
                <Typography variant="body1" className='booking_AccoCurrency'>
                {displayCurrency}{itineryTotalFlightPrice.toFixed(2)}
                </Typography>
              </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
            {itineraryFare?.paxFares.map((passengerFare: any, index: number) => {
                let baseAmount = passengerFare?.sellingBaseAmount?.amount 
                    ? passengerFare.sellingBaseAmount.amount 
                    : passengerFare?.fareBreakDown?.sellingBaseAmount?.amount 
                    ? passengerFare?.fareBreakDown?.sellingBaseAmount?.amount 
                    : 0;

                let baseCurrency = passengerFare?.sellingBaseAmount?.currency 
                    ? passengerFare.sellingBaseAmount.currency 
                    : passengerFare?.fareBreakDown?.sellingBaseAmount?.currency;

                const { passengerDetails, totalTaxes } = passengerFare;
                const taxes = returnTaxes(passengerFare);

                return (
                    <>
                        <Box className="booking_priceBody">
                            <Box>
                                <Typography variant="body2" className="booking_priceBodyTitle">
                                    {t('Base price')}
                                </Typography>
                                <Typography variant="body2" className="booking_priceBodysubTitle">
                                    {passengerDetails.quantity} {t(passengerDetails.type)} X{' '}
                                    {displayCurrency}
                                    {baseAmount && baseAmount !== 0 ? baseAmount.toFixed(2) : '0.00'}{' '}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" textAlign="right" className="booking_AccoCurrency">
                                    {displayCurrency}
                                    {(baseAmount * passengerDetails.quantity).toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="booking_priceBody">
                            <Box>
                                <Typography variant="body2" className="booking_priceBodyTitle">
                                    {t('Taxes and fees')}
                                </Typography>
                                <Typography variant="body2" className="booking_priceBodysubTitle">
                                    {passengerDetails.quantity} {t(passengerDetails.type)} X{' '}
                                    {displayCurrency}
                                    {taxes.toFixed(2)}{' '}
                                </Typography>
                            </Box>
                            <Typography variant="body2" className="booking_AccoCurrency">
                                {displayCurrency}
                                {(taxes * passengerDetails.quantity).toFixed(2)}
                            </Typography>
                        </Box>
                    </>
                );
            })}
          </AccordionDetails>
          </Accordion>
          {(insuranceTotal && parseFloat(insuranceTotal) > 0) ? (
              <Accordion>
                <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls={"panel1-content"}
                   id={"panel1-header"}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1" className='booking_AccoHeader'>{t('Travel Insurance')}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className='booking_AccoCurrency'>
                    {displayCurrency}{parseAmount(insuranceTotal)}
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Typography variant="body1" className='booking_AccoCurrency'> */}
                    <Box>
                    {insuranceBreakdown && Object.keys(insuranceBreakdown).map((val) => {
                        const plan = insuranceBreakdown[val];
                        // Check for adults
                        if(val != INSURANCEPLANCODE.DECLINED){
                          const planTotalAmount = (plan.adult + plan.child) * Number(plan.amount);
                          return (
                          <Box className="booking_priceBody" key={val}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant="body2" className='booking_priceBodyTitle'>{plan.planname}</Typography>
                                  {(plan.adult > 0) ? 
                                    (<Typography variant="body2" className='booking_priceBodysubTitle'>{plan.adult} {t(PASSENGERTYPE.ADULT)} X {displayCurrency}{parseAmount(plan.amount)}</Typography>) 
                                  : ''}
                                  {(plan.child > 0) ? (<Typography variant="body2" className='booking_priceBodysubTitle'>{plan.child} {t(PASSENGERTYPE.CHILD)} X {parseAmount(plan.childamount)} CAD</Typography>) 
                                  : ''}
                              </Grid>
                              <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Typography variant="body2" className='booking_priceBodyTitle'>{displayCurrency}{parseAmount(planTotalAmount)}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          );
                        }
                        return null;
                      })
                    }
                      {/* {insuranceBreakdown} */}
                    {/* </Typography> */}
                    </Box>
                </AccordionDetails>
              </Accordion>
            ): (
              (insuranceTotal && parseFloat(insuranceTotal) > 0) ? 
              <Box className="Booking_PriceHeader">
                  <Grid item xs={6}>
                  <Typography variant="body1" className='booking_AccoHeader'>{t('Travel Insurance')}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" className='booking_AccoCurrency'>
                  {displayCurrency}{parseAmount(insuranceTotal)}
                  </Typography>
                </Grid>
              </Box>
              :""
            )
          }

       
          {/* <Box className="Booking_priceTotal">
            <Grid item xs={6}>
              <Typography variant="body1" className='booking_AccoHeader'>Gift Card</Typography>
            </Grid>
          </Box>
          <Box>
                <Typography variant="body1" className='booking_AccoCurrency'>
                {giftCardTotal > 0 ? ` - ${giftCardTotal} CAD` : '0.00 CAD'}
                </Typography>
          </Box> */}
         {giftCardTotal > 0 ? (
            <Box className="Booking_priceFooter Booking_GiftCardFooter"  >
            <Box className="Booking_priceTotal">
                  <Box>
                    <Typography variant="body1" className='Booking_priceTotal'>{t('Gift Card')}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" className='booking_AccoCurrency'>
                    {displayCurrency}{giftCardTotal.toFixed(2)}
                    </Typography>
                  </Box>
              </Box>
             </Box>
         ):''} 
        

        </React.Fragment>
        {/* Display total price */}
        <Box className="Booking_priceFooter">
          <Box className="Booking_priceTotal">
            <Typography variant="h6" className='Booking_priceTotal'>{t('Total price')}</Typography>
            <Typography variant="h6" className='Booking_FlightPrice'>
            {(insuranceTotal && Number(insuranceTotal) > 0) ?
             'CA $'+ `${finalTotalPrice.toFixed(2)}` :'CA $'+ totalFlightPrice.toFixed(2)}
            </Typography>
          </Box>
          {/* <Box className="booking_FullPriceLink">
            <Typography variant="body2" className="booking_FullLink">
              Full price breakdown
            </Typography>
          </Box> */}
        </Box>
      </Box>
      {isBookingPage ? <WhyBookWithUs /> : null}
    </div>
  );
};

export default PriceSummaryComponent;
