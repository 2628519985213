export const ROUTES = {
    searchResultPage: "/searchResults",
    loginPage:"/login",
    searchWidgetPage:"/search",
    booking: "/booking",
    bookingConfirmation: "/bookingConfirmation",
    home:"/"
}

export const TRIPTYPE = {
    "ONEWAY":"OneWay",
    "ROUNDTRIP":"Return",
    "MULTICITY":"MultiCity"
}

export const PREFERREDCLASS={
    "Y":"Economy",
    "S":"PremiumEconomy",
    "C":"Business",
    "F":"First"
}


export const PASSENGERTYPE={
    ADULT:"Adult",
    CHILD:"Child",
    INFANT:"InfantInLap"
}

export const INSURANCESTR = {
    "provinceChange" : "provinceChange",
    "paxChange" : "paxChange",
    "paxInsChange": "paxInsChange"
}

export const paymentlabel = {
    FULLPAYMENT:"fullPayment",
    UPLIFTPAYMENT:"upliftpayment"
}

export const UPLIFTTRIPTYPE = {
    "ONEWAY":"oneway",
    "ROUNDTRIP":"roundtrip",
}

export const PAXQUANTITY = {
    ADULTCOUNT: 6,
    CHILDCOUNT: 4
}