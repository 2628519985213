import react from "react";
import moment from "moment";
const FILTER_CONSTANTS = {
    noOfStops: "noOfStops",
    findMinMaxValues: "findMinMaxValues",
    findCarriers: "findCarriers",
    getDepartureAirport: "getDepartureAirport",
    getArrivalAirport: "getArrivalAirport",
    getLayovers: "getLayovers",
    getDepartureTimeRange: "getDepartureTimeRange",
    getArrivalTimeRange: "getArrivalTimeRange",
    getTotalDuration: "getTotalDuration",
    getLayoverTime: "getLayoverTime",
    getBaggageData: "getBaggageData"
}

const FILTER_KEY_CONSTANTS = {
    "Stop1": "1 Stop",
    "Stop2": "2+ Stop",
    "NonStop": "NonStop",
    "minimumValue": "minimumValue",
    "maximumValue": "maximumValue",
    "fare": "fare",
    "count": "count",
    FREE_BAGGAGES: "FREE_BAGGAGES",
    // CHARGED_BAGGAGES: "CHARGED_BAGGAGES"
}

const DEFAULT_FILTERS = {
    [FILTER_CONSTANTS.noOfStops]: {},
    [FILTER_CONSTANTS.findMinMaxValues]: {},
    [FILTER_CONSTANTS.findCarriers]: {},
    [FILTER_CONSTANTS.getDepartureAirport]: {},
    [FILTER_CONSTANTS.getArrivalAirport]: {},
    [FILTER_CONSTANTS.getLayovers]: {},
    [FILTER_CONSTANTS.getDepartureTimeRange]: {},
    [FILTER_CONSTANTS.getArrivalTimeRange]: {},
    [FILTER_CONSTANTS.getTotalDuration]: {},
    [FILTER_CONSTANTS.getLayoverTime]: {},
    [FILTER_CONSTANTS.getBaggageData]: {},
}

export class SearchResultTranslation {
    // Will hold the current level data
    private currentLevelData: any[] = [];
    // Will hold fare option reference based on itinerary and outbound reference
    private firstLayerFareReferences: Record<string, any> = {}; // Initialized as an empty object
    // current segment level
    private currentLevelId: number = 0; // Initialized to 0
    // holds number of level and all their ids
    private allDataLevelIds: any = []; // Initialized as an empty array
    // Holds segment options and their levels
    private segmentOptions: Record<string, any> = {}; // Initialized as an empty object

    private referenceId:any[]=[]
    private paxCount = 0;

    private allFilters: any = JSON.parse(JSON.stringify(DEFAULT_FILTERS));
    constructor(searchResults: any, passengers: any) {
        this.calculatePaxCount(passengers);
        this.processAmountAsPerPaxCount = this.processAmountAsPerPaxCount.bind(this);
        if(searchResults?.faresOptions?.length >0){
            this.createIdDistribution(searchResults);
            this.processCurrentLevelData(); // Initialize current level data
            this.createFilters();
        }
    }

    private calculatePaxCount(passengers = []){
        passengers.forEach(pax => {
            this.paxCount += parseInt(pax.quantity);
        });
    }

    processAmountAsPerPaxCount(amount){
        return (amount/this.paxCount);
    }


    private createIdDistribution(searchResult: any) {
        const { faresOptions = [], segmentOptions = {} } = searchResult; // Destructured to get faresOptions and segmentOptions
        this.segmentOptions = segmentOptions; // Store segment options in the class instance
        faresOptions.forEach((fare: any) => {
            const { segmentReferences, itineraryFareId } = fare;
            const splitData = segmentReferences.split("#");
            const key = `${splitData[0]}`;
            this.createFareOptionsReferences(fare, key);
            this.createDataLevels(splitData, key);
        });
    }

    private createFareOptionsReferences(fare: any, key: string) {
        this.firstLayerFareReferences[key] = fare; // Store fare references in a dictionary
    }

    private createDataLevels(splitData: any, parentRefId: string) {
        let indexes = [...splitData];
        splitData.forEach((splitValue: any, index: number) => {
            let commonKey = "";
            if(index > 1){
                // this.allDataLevelIds[this.currentLevelId][refId.join("_")]
                let newSplitData = [...splitData];
                newSplitData.length = index;
                commonKey = newSplitData.join("_")
            }else{
                commonKey = indexes[0];
            }
            if(this.allDataLevelIds[index] && this.allDataLevelIds[index][commonKey]){
                this.allDataLevelIds[index][commonKey].push({ refId: splitValue, parentRefId: parentRefId })
            }else{
                let data = this.allDataLevelIds[index] || [];
                this.allDataLevelIds[index] =  {...data,[commonKey]: [{ refId: splitValue, parentRefId: parentRefId }]};
            }
        });
    }

    private processCurrentLevelData() {
        let refId = this.referenceId;
        const allCurrentLevelIds = this.currentLevelId == 0 
            ? this.allDataLevelIds[this.currentLevelId] 
            : this.allDataLevelIds[this.currentLevelId][refId.join("_")]; // Safely access current level IDs
        const currentSegments = this.segmentOptions[this.currentLevelId] || {}; // Safely access current segment options
    
        // Initialize a Set to track processed refIds
        const processedRefIds = new Set<string>();
    
        const composedObject = allCurrentLevelIds && Object.values(allCurrentLevelIds)
            .flat(1)
            .map((ref: any) => {
                // If refId is already processed, skip it
                if (processedRefIds.has(ref.refId)) {
                    return null; // Exclude duplicate entries
                }
    
                processedRefIds.add(ref.refId); // Add refId to the processed set
    
                const segment = currentSegments[ref.refId] || {};
                const fareOption = this.firstLayerFareReferences[ref.parentRefId] || {};
                return { 
                    ...segment, 
                    ...fareOption, 
                    ref: ref.refId, 
                    upliftAmount: (fareOption.totalFare/this.paxCount) * 100 
                }; // Combine segment and fare options
            })
            .filter(Boolean); // Remove null entries
    
        this.currentLevelData = composedObject; // Store the composed data in currentLevelData
    }

    // Setter for data level, with immutability in mind
    set setDataLevel(level: number) {
        this.currentLevelId = level;
        this.referenceId.length = level;
        this.processCurrentLevelData();
        this.createFilters(); // Ensure filters are created/updated
    }

    set setReferenceId(refId: any) {
        this.referenceId.push(refId);
    }


    // Getter for current level
    get getCurrentLevel() {
        return this.currentLevelId;
    }

    // Check if next level exists
    get doesNextLevelExist() {
        const nextLevel = this.currentLevelId + 1;
        return Boolean(this.allDataLevelIds[nextLevel]);
    }

    // Get current level data
    get getCurrentLevelData() {
        return this.currentLevelData;
    }

    // Get all filter data, processing as needed
    get allFilterData() {
        const filters = this.allFilters;
        filters[FILTER_CONSTANTS.getArrivalAirport] = Object.values(filters[FILTER_CONSTANTS.getArrivalAirport]);
        filters[FILTER_CONSTANTS.getDepartureAirport] = Object.values(filters[FILTER_CONSTANTS.getDepartureAirport]);
        return filters;
    }

    get getReferenceId() {
        return this.referenceId;
    }

    get getDataLevelIds(){
        return this.allDataLevelIds
    }

    // Utilities

    private createFilters() {
        this.allFilters = JSON.parse(JSON.stringify(DEFAULT_FILTERS));
        this.currentLevelData.forEach(segment => {
            this.calculateNumberOfStops(segment);
            this.findMinMaxValues(segment)
            this.findCarriers(segment)
            this.getDepartureAirport(segment);
            this.getArrivalAirport(segment)
            this.getLayovers(segment);
            this.getDepartureTimeRange(segment);
            this.getArrivalTimeRange(segment);
            this.getTotalDuration(segment);
            this.getLayoverTime(segment);
            this.getBaggageData(segment);
        });
        
    }

    private getBaggageData({ itinerarySummary, totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare);
        const { freeBaggageAllowance, chargedBaggageAllowance = [] } = itinerarySummary;
        if (freeBaggageAllowance && (parseInt(freeBaggageAllowance?.quantity) > 0 || parseInt(freeBaggageAllowance?.weight) > 0)) {
            let valueStored = this.allFilters[FILTER_CONSTANTS.getBaggageData];
            if (valueStored[FILTER_KEY_CONSTANTS.FREE_BAGGAGES]) {
                if (valueStored[FILTER_KEY_CONSTANTS.FREE_BAGGAGES][FILTER_KEY_CONSTANTS.fare] > parseFloat(totalFare)) {
                    valueStored[FILTER_KEY_CONSTANTS.FREE_BAGGAGES][FILTER_KEY_CONSTANTS.fare] = parseFloat(totalFare);
                }
                valueStored[FILTER_KEY_CONSTANTS.FREE_BAGGAGES][FILTER_KEY_CONSTANTS.count] += 1;
            } else {
                valueStored[FILTER_KEY_CONSTANTS.FREE_BAGGAGES] = {
                    [FILTER_KEY_CONSTANTS.fare]: parseFloat(totalFare),
                    [FILTER_KEY_CONSTANTS.count]: 1,
                    baggage: FILTER_KEY_CONSTANTS.FREE_BAGGAGES
                }
            }
        }
        // if (chargedBaggageAllowance && (parseInt(chargedBaggageAllowance?.[0]?.firstPiece) > 0 || parseInt(chargedBaggageAllowance?.[0]?.lastPiece) > 0)) {
        //     let fare = parseFloat(chargedBaggageAllowance?.[0]?.amount);
        //     let valueStored = this.allFilters[FILTER_CONSTANTS.getBaggageData];
        //     if (valueStored[FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES]) {
        //         if (valueStored[FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES][FILTER_KEY_CONSTANTS.fare] > fare) {
        //             valueStored[FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES][FILTER_KEY_CONSTANTS.fare] = fare;
        //         }
        //         valueStored[FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES][FILTER_KEY_CONSTANTS.count] += 1;
        //     } else {
        //         valueStored[FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES] = {
        //             [FILTER_KEY_CONSTANTS.fare]: fare,
        //             [FILTER_KEY_CONSTANTS.count]: 1,
        //             baggage: FILTER_KEY_CONSTANTS.CHARGED_BAGGAGES
        //         }
        //     }
        // }
    }

    private getLayoverTime({ layovers = [] }) {
        let valueStored = this.allFilters[FILTER_CONSTANTS.getLayoverTime];
        let layoverTime = 0
        layovers.forEach(({ durationMinutes }) => {
            layoverTime += Number(durationMinutes)
        })
        if (Object.keys(valueStored)?.length > 0) {
            if (layoverTime != 0 && valueStored.minLayoverDuration > layoverTime) {
                this.allFilters[FILTER_CONSTANTS.getLayoverTime]["minLayoverDuration"] = layoverTime;
                this.allFilters[FILTER_CONSTANTS.getLayoverTime]["minLayoverTime"] = this.timeConvert(layoverTime)
            }
            if (layoverTime != 0 && valueStored.maxLayoverDuration < layoverTime) {
                this.allFilters[FILTER_CONSTANTS.getLayoverTime]["maxLayoverDuration"] = layoverTime;
                this.allFilters[FILTER_CONSTANTS.getLayoverTime]["maxLayoverTime"] = this.timeConvert(layoverTime)
            }
        } else {
            this.allFilters[FILTER_CONSTANTS.getLayoverTime] = {
                minLayoverDuration: layoverTime,
                maxLayoverDuration: layoverTime,
                minLayoverTime: this.timeConvert(layoverTime),
                maxLayoverTime: this.timeConvert(layoverTime)
            }
        }
    }

    private getTotalDuration({ totalDurationMinutes }) {
        let valueStored = this.allFilters[FILTER_CONSTANTS.getTotalDuration];
        if (Object.keys(valueStored)?.length > 0) {
            if (valueStored.minTotalDuration > parseFloat(totalDurationMinutes)) {
                this.allFilters[FILTER_CONSTANTS.getTotalDuration]["minTotalDuration"] = parseFloat(totalDurationMinutes)
                this.allFilters[FILTER_CONSTANTS.getTotalDuration]["minTotalTime"] = this.timeConvert(parseFloat(totalDurationMinutes))
            }
            if (valueStored.maxTotalDuration < parseFloat(totalDurationMinutes)) {
                this.allFilters[FILTER_CONSTANTS.getTotalDuration]["maxTotalDuration"] = parseFloat(totalDurationMinutes)
                this.allFilters[FILTER_CONSTANTS.getTotalDuration]["maxTotalTime"] = this.timeConvert(parseFloat(totalDurationMinutes))
            }
        } else {
            this.allFilters[FILTER_CONSTANTS.getTotalDuration] = {
                minTotalDuration: parseFloat(totalDurationMinutes),
                maxTotalDuration: parseFloat(totalDurationMinutes),
                minTotalTime: this.timeConvert(parseFloat(totalDurationMinutes)),
                maxTotalTime: this.timeConvert(parseFloat(totalDurationMinutes))
            }
        }
    }

    private getArrivalTimeRange({ itinerarySummary }) {
        const { arrivalDateTime } = itinerarySummary;
        const data = this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange];
        if (Object.keys(data)?.length > 0) {
            let convertedTime = new Date(arrivalDateTime).getTime();
            const { earliestArrivalTime, earliestArrivalDateTime, latestArrivalTime, latestArrivalDateTime } = data;
            if (earliestArrivalDateTime > convertedTime) {
                this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange]["earliestArrivalTime"] = moment(arrivalDateTime).format('h:mm A');
                this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange]["earliestArrivalDateTime"] = new Date(arrivalDateTime).getTime();
            }
            if (latestArrivalDateTime < convertedTime) {
                this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange]["latestArrivalTime"] = moment(arrivalDateTime).format('h:mm A');
                this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange]["latestArrivalDateTime"] = new Date(arrivalDateTime).getTime();
            }
        } else {
            this.allFilters[FILTER_CONSTANTS.getArrivalTimeRange] = {
                earliestArrivalTime: moment(arrivalDateTime).format('h:mm A'),
                latestArrivalTime: moment(arrivalDateTime).format('h:mm A'),
                earliestArrivalDateTime: new Date(arrivalDateTime).getTime(),
                latestArrivalDateTime: new Date(arrivalDateTime).getTime()
            }
        }
    }

    private getDepartureTimeRange({ itinerarySummary }) {
        const { departureDateTime } = itinerarySummary;
        const data = this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange];
        if (Object.keys(data)?.length > 0) {
            let convertedTime = new Date(departureDateTime).getTime();
            const { earliestDepartureTime, earliestDepartureDateTime, latestDepartureTime, latestDepartureDateTime } = data;
            if (earliestDepartureDateTime > convertedTime) {
                this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange]["earliestDepartureTime"] = moment(departureDateTime).format('h:mm A');
                this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange]["earliestDepartureDateTime"] = new Date(departureDateTime).getTime()
            }
            if (latestDepartureDateTime < convertedTime) {
                this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange]["latestDepartureTime"] = moment(departureDateTime).format('h:mm A');
                this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange]["latestDepartureDateTime"] = new Date(departureDateTime).getTime()
            }
        } else {
            this.allFilters[FILTER_CONSTANTS.getDepartureTimeRange] = {
                earliestDepartureTime: moment(departureDateTime).format('h:mm A'),
                latestDepartureTime: moment(departureDateTime).format('h:mm A'),
                earliestDepartureDateTime: new Date(departureDateTime).getTime(),
                latestDepartureDateTime: new Date(departureDateTime).getTime()
            }
        }
    }

    private getLayovers({ layovers = [] }) {
        layovers.forEach(layover => {
            const { airport, airportCity } = layover;
            let valueStored = this.allFilters[FILTER_CONSTANTS.getLayovers][airport];
            if (!valueStored) {
                this.allFilters[FILTER_CONSTANTS.getLayovers][airport] = {
                    airport: airport,
                    airportCity: airportCity
                }
            }
        })
    }

    private getArrivalAirport({ itinerarySummary, totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare);
        const { arrivalAirport = "", arrivalAirportCity = "" } = itinerarySummary;
        let valueStored = this.allFilters[FILTER_CONSTANTS.getArrivalAirport][arrivalAirport];
        if (valueStored) {
            if (valueStored.fare > totalFare) {
                this.allFilters[FILTER_CONSTANTS.getArrivalAirport][arrivalAirport][FILTER_KEY_CONSTANTS.fare] = totalFare;
            }
            this.allFilters[FILTER_CONSTANTS.getArrivalAirport][arrivalAirport][FILTER_KEY_CONSTANTS.count] += 1;
        } else {
            this.allFilters[FILTER_CONSTANTS.getArrivalAirport][arrivalAirport] = {
                arrivalAirport: arrivalAirport,
                arrivalAirportCity: arrivalAirportCity,
                [FILTER_KEY_CONSTANTS.fare]: totalFare,
                count: 1
            }
        }
    }

    private getDepartureAirport({ itinerarySummary, totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare);
        const { departureAirport = "", departureAirportCity = "" } = itinerarySummary;
        let valueStored = this.allFilters[FILTER_CONSTANTS.getDepartureAirport][departureAirport];
        if (valueStored) {
            if (valueStored.fare > totalFare) {
                this.allFilters[FILTER_CONSTANTS.getDepartureAirport][departureAirport][FILTER_KEY_CONSTANTS.fare] = totalFare;
            }
            this.allFilters[FILTER_CONSTANTS.getDepartureAirport][departureAirport][FILTER_KEY_CONSTANTS.count] += 1;

        } else {
            this.allFilters[FILTER_CONSTANTS.getDepartureAirport][departureAirport] = {
                departureAirport: departureAirport,
                departureAirportCity: departureAirportCity,
                [FILTER_KEY_CONSTANTS.fare]: totalFare,
                count: 1
            }
        }

    }

    private findCarriers({ flightSummary = [], totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare);
        let [flight = {}] = flightSummary;
        let { carrier = "", carrierName = "" } = flight;
        let valueStored = this.allFilters[FILTER_CONSTANTS.findCarriers][carrier];
        if (valueStored) {
            if (valueStored[FILTER_KEY_CONSTANTS.fare] > parseFloat(totalFare)) {
                this.allFilters[FILTER_CONSTANTS.findCarriers][carrier][FILTER_KEY_CONSTANTS.fare] = parseFloat(totalFare);
            }
            this.allFilters[FILTER_CONSTANTS.findCarriers][carrier][FILTER_KEY_CONSTANTS.count] += 1;
        } else {
            this.allFilters[FILTER_CONSTANTS.findCarriers][carrier] = {
                [FILTER_KEY_CONSTANTS.fare]: totalFare,
                count: 1,
                carrier,
                carrierName
            }
        }
    }

    private findMinMaxValues({ totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare);
        const minimumValue = this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.minimumValue];
        const maximumValue = this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.maximumValue];
        if (minimumValue) {
            if (minimumValue > totalFare) {
                this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.minimumValue] = totalFare;
            }
            if (maximumValue < totalFare) {
                this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.maximumValue] = totalFare;
            }
        } else {
            this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.minimumValue] = totalFare;
            this.allFilters[FILTER_CONSTANTS.findMinMaxValues][FILTER_KEY_CONSTANTS.maximumValue] = totalFare;
        }
    }

    private calculateNumberOfStops({ layovers = [], totalFare }) {
        totalFare = this.processAmountAsPerPaxCount(totalFare)
        let stopKey = layovers?.length === 1 ? FILTER_KEY_CONSTANTS.Stop1 : layovers?.length > 1 ? FILTER_KEY_CONSTANTS.Stop2 : FILTER_KEY_CONSTANTS.NonStop;
        let valueStored = this.allFilters[FILTER_CONSTANTS.noOfStops][stopKey];
        if (valueStored) {
            if (valueStored[FILTER_KEY_CONSTANTS.fare] > parseFloat(totalFare)) {
                this.allFilters[FILTER_CONSTANTS.noOfStops][stopKey][FILTER_KEY_CONSTANTS.fare] = parseFloat(totalFare);
            }
            this.allFilters[FILTER_CONSTANTS.noOfStops][stopKey][FILTER_KEY_CONSTANTS.count] += 1;
        } else {
            this.allFilters[FILTER_CONSTANTS.noOfStops][stopKey] = { name: stopKey, fare: parseFloat(totalFare), count: 1 };
        }
    }

    private timeConvert = (n) => {
        let number = n;
        let hours = (number / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        return number = rhours + " hr " + rminutes + " min";
    }

}