import React from 'react';
import { Grid, Box, Typography, TextField, Container, RadioGroup, Radio, FormControlLabel, Button, Checkbox, FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material';
import LogosAnimation from '../common/BookWithPaymentComponents/loaders/LogosAnimation';
import LoadingSearchCard from '../Loader/searchCardLoader';

const Loader = () => {
    return (
        <>


        
            <Container className="loader-list loader-searchList ">
                <Grid container spacing={2}>

                    <Grid item sm={4} xs={12}>
                  
                        <div className="center-space">
                            <div className="placeholder short"></div>
                            <div className="placeholder short"></div>
                        </div>
                        <div className='bg-card'>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                        </div>
                        <div className='bg-card'>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item sm={8} xs={12}>
                    <Box className=" SearchFare_Loader">
                    <LogosAnimation/>
                     </Box>
                        <div className="center-space">
                    
                            <div className="placeholder short"></div>
                            <div className="placeholder short"></div>
                        </div>
                        <div className="placeholder full" style={{ height: 100 }}>
                       
                        </div>
                        <LoadingSearchCard/>
                    </Grid>

                </Grid>

            </Container>
        </>
    );
};

export default Loader;