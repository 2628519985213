import React, { useEffect, useState } from 'react';
import { AutoSuggestAPI, SearchResultAPIV2, MultipleAutoSuggestAPI } from "gigi-api-layer";
import {SearchWidgetComponent,SearchRequests, processFromToDate, ComposedSearchRequestURL } from 'gigi-component-library';
import { findMinMaxValues, fetchAllFilterResults } from '../../utility/commonFunctions';
import { UpdateSearchResultsData, SearchIsLoading, setTripType,UpdateSearchRequest, UpdateCorrelationId, setSearchResultHit } from '../../redux/actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { ROUTES, TRIPTYPE, PREFERREDCLASS, PAXQUANTITY } from '../../utility/constants';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import extractDomain from 'extract-domain';
import { GetBrandingData } from 'gigi-api-layer/src';
import { updateBrandedFaresData } from '../../redux/actions/brandedFare';
import { v4 as uuidv4 } from 'uuid';
import { ErrorNotifier, ErrorHandler } from 'gigi-component-library';
interface QueryParams {
    trip: string;
    adults: string;
    children: string
    preferedClass:string;
    view:string;
    lang:string;
    uid:string;
    cabinType:string;
    ages:string[]
    leg:string[]
}
interface Leg {
    from: string;
    to: string;
    date: string;
}
interface ComposedReqObj {
    legs: Leg[];
    infants: number[];
    childrens: number[];
    adults: string;
    tripType: string;
    cabinTypeValue: string;
    multiSuggest?: any;
}

const SearchComponent = ({showSteppers = true, processResponsiveDesign=false}) => {
    const [callAutoSuggestAPI, autoSuggestResults, autoSuggestInProgress, autoSuggestError]= AutoSuggestAPI()
    const [callMultiSuggestAPI, multiSuggestResults, multiSuggestInProgress, multiSuggestError]= MultipleAutoSuggestAPI()
    const [callSearchResultsAPI, searchResults, searchInProgress, searchError]= SearchResultAPIV2();
    const [getBranding, brandingData, brandingLoader, brandingError] = GetBrandingData();
    const searchRequest = useSelector((state:any)=>state.search.searchRequest);
    const searchResultApiHit = useSelector((state:any)=>state.search.searchClicked);
    const bookingAgency = useSelector((state: any) => state.languageChange.bookingAgency);
    const language = useSelector((state: any) => state.languageChange.language);

    const [brandUrl, setBrandUrl] = useState("");
    const [urlData, setUrlData] = useState<ComposedReqObj>();
    const [saveSearchRequest,setSaveSearchRequest]=useState<any>({});
    const [stopSearch,setStopSearch]=useState(false);
    const [savedUUID,setSavedUUID] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getTripType = (tripType) => {
        dispatch(setTripType(tripType))
    }
    const location = useLocation();

    // useEffect(() => {
    //     let url = extractDomain(window.location.hostname);
    //     getBranding({site: url})
    // }, []);

    useEffect(() => {
        if (multiSuggestResults?.length) {
            let urlLocalData = { ...urlData };
            let { legs } = urlLocalData;
            const airportCodes = mapAirportCodes(multiSuggestResults);
            legs.forEach(leg => {
                leg["fromLabel"] = airportCodes[leg.from]?.label,
                leg["toLabel"] = airportCodes[leg.to]?.label
            });
            urlLocalData["multiSuggest"] = multiSuggestResults;
            setUrlData(urlLocalData); 
        }
    }, [multiSuggestResults]);

    const mapAirportCodes = (airportCodes: []) => {
        let codeObj = {};
        airportCodes.forEach((airport: any) => {
            const {code} = airport.value;
            codeObj[code] = airport;
        });
        return codeObj;
    }

    // useEffect(() => {
    //     if(brandingData?.length){
    //         let [branding] = brandingData;
    //         if(branding.url) setBrandUrl(branding.url)
              
    //         document.documentElement.style.setProperty('--main-primary-branding', branding.main_branding_color);
    //         document.documentElement.style.setProperty('--main-secondary-color', branding.main_branding_color_secondary);
    //         document.documentElement.style.setProperty('--main-primary-Color', branding.button_color);
    //         document.documentElement.style.setProperty('--main-primary-Button', branding.button_color);
    //         document.documentElement.style.setProperty('--Button-Text-Color', branding.button_text_color);
    //         document.documentElement.style.setProperty('--anchor-links', branding.anchor_links);
    //         document.documentElement.style.setProperty('--SearchBackground', branding.search_background);
    //         document.title = branding.site?.toUpperCase();
    //   }
    // },[brandingData])
    
    useEffect(()=>{
        if(!searchInProgress){
            dispatch(SearchIsLoading(false));

        }else{
            dispatch(setSearchResultHit(true))
            dispatch(SearchIsLoading(true));
        }
    }, [searchInProgress]);

    useEffect(() => {
        if (searchResults) {
            dispatch(UpdateSearchResultsData(searchResults));
        } else if (searchResultApiHit || location.search === '') {
            dispatch(UpdateSearchResultsData([]))
            dispatch(updateBrandedFaresData([]))
        }
    }, [searchResults])

    useEffect(() => {
        fetchSearchResult()
    }, [location.search])

    const fetchSearchResult = async () => {
        const queryParams: any = queryString.parse(location.search, { arrayFormat: 'bracket' });
        let allOAndDPairs: any = []
        if("uid" in queryParams && !("trip" in queryParams) ){
            if(searchRequest){
                const { uid="" }: any = queryString.parse(location.search, { arrayFormat: 'bracket' });
                let queryParams = ComposedSearchRequestURL(searchRequest, uid);
                navigate(`/?${queryParams}`, { replace: true });
            }
        } else if ('trip' in queryParams) {
            const { adults = "0", trip = "", preferedClass = "", ages = [], leg = [], uid="" }: QueryParams = queryParams;
            if(parseInt(adults) > PAXQUANTITY.ADULTCOUNT || ages?.length > PAXQUANTITY.CHILDCOUNT){
                ErrorHandler("ERROR", "Please select valid pax count", null, 'Ok', 'error', 'alertModal');
                dispatch(UpdateSearchResultsData([]))
                dispatch(updateBrandedFaresData([]))
                return;
            }
            let infantCount = ages.reduce((acc, cv) => {
                if(parseInt(cv) < 2){
                    return acc + parseInt(cv);
                }
            }, 0)
            if(parseInt(adults) < infantCount){
                ErrorHandler("ERROR", "Infant count should be less than or equal to the number of adults", null, 'Ok', 'error', 'alertModal');
                dispatch(UpdateSearchResultsData([]))
                dispatch(updateBrandedFaresData([]))
                return;
            }
            const legs = leg.map(leg => {
                let [from, to, date] = leg.split(';');
                from = from?.toUpperCase();
                to = to?.toUpperCase();
                allOAndDPairs.push(from, to)
                return { from, to, date };
            });
            allOAndDPairs = allOAndDPairs.filter((pair, index) => index === allOAndDPairs.indexOf(pair));
            const { infants, childrens } = (Array.isArray(ages) ? ages : []).reduce((counts, age) => {
                const parsedAge = parseInt(age, 10);
                if (parsedAge < 2) {
                    counts.infants.push(parsedAge);
                } else {
                    counts.childrens.push(parsedAge);
                }
                return counts;
            }, { infants: [], childrens: [] });

            let {fromTo, advancedForm = []} = processFromToDate(trip, legs);
            
            let obj = {
                adultCount: adults,
                childCount: childrens?.length,
                childrenAges: childrens,
                infantAges: infants,
                infantCount: infants?.length,
                setTrip: TRIPTYPE[trip.toUpperCase()],
                isNonStop: false,
                freeBag: 0,
                cabinType: PREFERREDCLASS[preferedClass.toUpperCase()],
                ...fromTo
            }

            let saveComposedReqObj = {
                legs,
                infants,
                childrens,
                adults,
                tripType: TRIPTYPE[trip.toUpperCase()],
                cabinTypeValue: PREFERREDCLASS[preferedClass.toUpperCase()]
            }

            try {
                dispatch(UpdateSearchResultsData([]))
                dispatch(UpdateCorrelationId(uid))
                let { requestPayload,searchFormValid } =  new SearchRequests(obj, advancedForm, true);
                setUrlData(saveComposedReqObj)
                callMultiSuggestAPI(allOAndDPairs);
                if(searchFormValid){
                    
                    if (bookingAgency != "") {
                        requestPayload.agency =bookingAgency;
                        dispatch(UpdateSearchRequest(requestPayload));
                        await callSearchResultsAPI(requestPayload,uid);
                    }else{
                        setStopSearch(true);
                        setSaveSearchRequest(requestPayload);
                        setSavedUUID(uid);
                        return;
                    }
                    
                }
            } catch (error) {
                console.error('Search request failed:', error);
            }
        }

    }

    useEffect(()=>{
        if (stopSearch && (bookingAgency != "")) {
            recallSearch();
        }
    },[stopSearch,bookingAgency]);

    const recallSearch =async () => {
        let payload={...saveSearchRequest};
        payload.agency = bookingAgency;
        await callSearchResultsAPI(payload,savedUUID);
        dispatch(UpdateSearchRequest(payload));
        setStopSearch(false);
        setSavedUUID("")
    }

     const handleSearchResult = (payload: any) => {
        const correlationId = `UID${uuidv4()}`;
        dispatch(UpdateSearchResultsData([]))        
        dispatch(UpdateCorrelationId(correlationId))
        dispatch(UpdateSearchRequest(payload));
        let queryParams = ComposedSearchRequestURL(payload, correlationId);
        navigate(`/?${queryParams}`, { replace: true });
      };

    return (
        <div>
            {/* {brandUrl && <img src={brandUrl} style={{width: "100%"}} />} */}
            <SearchWidgetComponent data={autoSuggestResults} autoSuggestCall={callAutoSuggestAPI} searchResultData={(payload) => handleSearchResult(payload)}  getTripType={getTripType} multiSuggestResults={multiSuggestResults} urlData={urlData} showSteppers={showSteppers} processResponsiveDesign={processResponsiveDesign} language={language}/>
        </div>
    );
};

export default SearchComponent;