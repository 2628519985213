import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Box, MenuItem, OutlinedInput, InputLabel, Container, RadioGroup, Radio, FormControlLabel, FormControl, Checkbox, Select, Typography } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import './_SearchWidget.scss';
import SerachTabContent from './SerachTabContent';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
//@ts-ignore
import { cabinType, tabsOptions, tabPanelOptions } from '../../utils/constant';
import { Stepper } from '../Stepper/Stepper';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function SearchTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className='tabpanel'
    >
      {value === index && (
        <Box sx={{}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function searchTabs(index: number) {
  const [value, setValue] = React.useState(0);
  return {
    id: `searchTabs-${index}`,
    'aria-controls': `searchTabspanel-${index}`,
  };
}


export default function SearchWidget(props: any) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');
  const [value, setValue] = React.useState(0);
  const [widgetShow, setWidgetShow] = React.useState(true);
  const {urlData, language} = props;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };  
  const [filghtClass, setFilghtClass] = React.useState('Economy');

  const handleChangeClass = ({ target }: any) => {
    setFilghtClass(target.value as string);
  };

  const reset = () => {
    setValue(0)
  }

  const handleSearchWidget = (newValue: any) => {
    setWidgetShow(newValue);
    // console.log("newValue",newValue)
  }

  interface dropdown {
    label: string;
    value: number;
  }

  useEffect(() => {
    props.getTripType(tabPanelOptions[value])
  }, [value])

  useEffect(() => {
    if(urlData){
      setUrlData();
    }
  }, [urlData])

  const setUrlData = () => {
    const {cabinTypeValue, tripType} = urlData;
    const tripTypeValue = tabPanelOptions.find(tab => tab.label === tripType);
    setFilghtClass(cabinTypeValue);
    setValue(tripTypeValue?.value || 0);
  }
  return (
    <>
      {/* <Container>
        <Grid item sm={12}>
          <Box className="SearchStepper">
            <SearchStepper StepperTitle={'Select Flight to New York'} StepperFinish={true} StepperCount={1} StepperActive={true} />
            <SearchStepper StepperTitle={t('Booking')} StepperFinish={false} StepperCount={2} StepperActive={true} />
            <SearchStepper StepperTitle={t('Confirmation')} StepperFinish={false} StepperCount={3} StepperActive={false} />
          </Box>
        </Grid>
      </Container> */}
      {props.showSteppers ? <Stepper page="SEARCH" /> : null}

      <Box className={[
        "SearchWidget",
        !widgetShow ? "widget_setPadding" : "",
        props.processResponsiveDesign ? "processResponsiveDesign" : ""
        ].join(" ")}>
        {!matches && !widgetShow &&
          <Box className="SearchWidgetMb">
            <Container>
              <Grid container>
                <Grid item sm={11} xs={10}>
                  <Typography className='SearchWidgetMb_title'>
                    Toronto (YYZ) {t('to')} New York (LGA)
                  </Typography>
                  <Typography className='SearchWidgetMb_subTitle'>
                    {t('Round trip')} | Aug 19, 2024 - Aug 26, 2024 | 2 {t('Adults')}
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={2}>
                  <Box sx={{ textAlign: 'right' }}>
                    <EditIcon onClick={() => setWidgetShow(true)} className='searchWidgetMb_Open' />
                  </Box>
                </Grid>

              </Grid>
            </Container>
          </Box>}

        <Container className={(!widgetShow && "searchwidgetHide")}>

          <Box className="SearchWidget__tabsBox">
            <Tabs value={value} onChange={handleChange} aria-label="tabs" className="SearchWidget__tabs">
              {tabsOptions.map((option: dropdown) => (
                <Tab
                  key={option.value}
                  label={
                    <RadioGroup
                      className='searchWidgetRadio'
                      value={value}
                      onChange={(e) => handleChange(e, option.value)}
                    >
                      <FormControlLabel value={option.value} control={<Radio />} label={t(option.label)} />
                    </RadioGroup>
                  }
                  {...searchTabs(option.value)}
                />
              ))}
            </Tabs>
            <FormControl className='searchClass-label'>

                <Select className='searchWidgetSel searchWidget_SortBtn' value={filghtClass} onChange={handleChangeClass} fullWidth label="Age">
                  {cabinType.map(option => (
                    <MenuItem key={option.value} value={option.value} style={{ fontSize: '14px' }}>
                      {t(option.label)}
                    </MenuItem>)
                  )}
                </Select>
            </FormControl>
          </Box>

          {tabPanelOptions.map((option: dropdown) => (
            <SearchTabPanel key={option.value} value={value} index={option.value}>
              <SerachTabContent setTrip={option.label} reset={reset} {...props} cabinType={filghtClass} searchWidgetHide={(p: any) => handleSearchWidget(p)} urlData={urlData} language={language}/>
            </SearchTabPanel>
          ))}

        </Container>
      </Box>
    </>

  );
}
