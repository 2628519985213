// import { mealArr, assistanceArr, wheelChairArr, monthNames, PassengerType } from "./constant"
// import CemairAirlineLogo from "./../../Assets/cemair-logo.png";
// import AirlinkAirlineLogo from "./../../Assets/Airlink-airline-logo(4Z).png"
// import proFlightZambia from "./../../Assets/proflight-zambia-logo.png"
// import NeosLogo from "./../../Assets/neos-logo.png";
// import Q4 from "./../../Assets/q4.png";
import moment from "moment";
// import tz from 'moment-timezone';
// import { getAge } from "./bookingCommon";


export const convertCamelCase = (string) => {
  if (string && string.trim().length === "") return
  return string && string[0].toUpperCase() + string.slice(1).toLowerCase()

};

export const changeDateFormat = (date, format) => {
  if (date) {
    var d = new Date(date);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let day = ("0" + d.getDate()).slice(-2);
    let year = d.getFullYear();
    if (format === "dashed") return `${year}-${month}-${day}`
    if (format === "slash") return `${month}/${day}/${year}`
    if (format === "monthFormat") return `${d.getDate()} ${monthNames[d.getMonth()]} ${year}`
    if (format === "ddmmyyslash") return `${day}/${month}/${year}`
  } else {
    return ""
  }

};

export const getSpecificValueFromArr = (key, value, arrName, specificResultKey, specifiedArr) => {
  console.log("flight details", key, value, arrName, specificResultKey, specifiedArr)
  let arr = []
  if (arrName === "meal") arr = mealArr
  if (arrName === 'assistance') arr = assistanceArr
  if (arrName === 'wheelChair') arr = wheelChairArr
  if (arrName === "any") arr = specifiedArr

  let matchedObj = arr.find(obj => { return obj[key] === value })
  return matchedObj && matchedObj[specificResultKey]
}

export const getPrefix = (gender) => {
  if (gender === undefined) return ""
  if (gender === "Male" || gender === "male") return "Mr"
  if (gender === "Female" || gender === "female") return "Mrs"
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  let a = "<br />";

  let newStr = splitStr.join(' ');
  newStr = newStr.replace(/\n/g, a);
  return newStr;
}


export const getCurrentWeek = () => {
  var curr_date = new Date();

  var day = curr_date.getDay();

  var diff = curr_date.getDate() - day + (day === 0 ? -6 : 1); // 0 for sunday

  var week_start_tstmp = curr_date.setDate(diff);

  var week_start = new Date(week_start_tstmp);

  var week_start_date = formattedDate(week_start);

  var week_end = new Date(week_start_tstmp);  // first day of week 

  week_end = new Date(week_end.setDate(week_end.getDate() + 6));

  var week_end_date = formattedDate(week_end);
  let dateobj = { from: week_start_date, to: week_end_date }
  return dateobj
}

export const getLastSevenDays = () => {
  var curr_date = new Date();
  const end_date = formattedEndDate(new Date());
  const start_date = moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00') //formattedDate( new Date(new Date(curr_date.getDate() >= 7) ? new Date().setDate(curr_date.getDate() - 7) : new Date( new Date().getFullYear(), new Date().getMonth()-1 ,new Date( new Date().getFullYear(), new Date().getMonth(),0).getDate() - (7 - curr_date.getDate())) ));
  let dateobj = { from: start_date, to: end_date }
  return dateobj
}

export const getLastFourWeeks = () => {
  var curr_date = new Date();
  var day = curr_date.getDay();
  const end_date = formattedEndDate(new Date());
  const start_date = moment().subtract(28, 'days').format('YYYY-MM-DDT00:00:00') //formattedDate(new Date(curr_date.getDate() >= 28? new Date(new Date().setDate(curr_date.getDate() - day - 28)) : new Date( new Date().getFullYear(), new Date().getMonth() -1 ,new Date( new Date().getFullYear(), new Date().getMonth(),0).getDate() - (28 - curr_date.getDate())) ));
  let dateobj = { from: start_date, to: end_date }

  return dateobj
}

export const getLastSixMonths = () => {
  var curr_date = new Date();
  const curr_month = curr_date.getMonth()
  const start = curr_month - 6 >= 0 ? new Date(curr_date.getFullYear(), curr_month - 6, 1) : new Date(curr_date.getFullYear() - 1, (11 - (6 - (curr_date.getMonth() + 1))), 1)
  const end_date = formattedEndDate(new Date());
  const start_date = moment().subtract(6, 'months').format('YYYY-MM-DDT00:00:00') //formattedDate(start);
  let dateobj = { from: start_date, to: end_date }
  return dateobj
}

export const getLastTwelveMonths = () => {
  var curr_date = new Date();
  const start = new Date(curr_date.getFullYear() - 1, (11 - (12 - (curr_date.getMonth() + 1))), 1)
  const end_date = formattedEndDate(new Date());
  const start_date = moment().subtract(12, 'months').format('YYYY-MM-DDT00:00:00') //formattedDate(start);
  let dateobj = { from: start_date, to: end_date }
  return dateobj
}

export const formattedDate = (date) => {
  var m = ("0" + (date.getMonth() + 1)).slice(-2);
  var d = ("0" + date.getDate()).slice(-2);
  var y = date.getFullYear();
  return y + '-' + m + '-' + d + 'T00:00:00';
}

export const formattedEndDate = (date) => {
  var m = ("0" + (date.getMonth() + 1)).slice(-2);
  var d = ("0" + date.getDate()).slice(-2);
  var y = date.getFullYear();
  return y + '-' + m + '-' + d + 'T23:59:59';
}

export const getCurrentMonth = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var monthStartDate = formattedDate(firstDay);
  var monthEndDate = formattedDate(lastDay);
  let dateobj = { from: monthStartDate, to: monthEndDate }
  return dateobj
}

// export const formatNextDayTime = (HeaderDepartureDate, HeaderArrivalDate) => {

//   let currentTime = moment('23:59', "HH:mm");
//   let startTime = moment(moment.utc(HeaderDepartureDate).locale('en').format("HH:mm"), "HH:mm");
//   let endTime = moment(moment.utc(HeaderArrivalDate).locale('en').format("HH:mm"), "HH:mm");

//   let newStartTime = moment.utc(HeaderDepartureDate).locale('en').format("ddd MMM DD, YYYY")
//   let newEndTime = moment.utc(HeaderArrivalDate).locale('en').format("ddd MMM DD, YYYY")

//   currentTime.toString();
//   startTime.toString();
//   endTime.toString();

//   if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
//     endTime.add(1, "days");       // handle spanning days endTime

//     if (currentTime.hour() <= 12) {
//       currentTime.add(1, "days");       // handle spanning days currentTime
//     }
//   }

//   let isBetween = currentTime.isBetween(startTime, endTime);
//   var msDiff = new Date(newEndTime).getTime() - new Date(newStartTime).getTime();    //Future date - current date
//   var difference = Math.floor(msDiff / (1000 * 60 * 60 * 24));

//   return Number(difference) !== 0 ? <> {moment.utc(HeaderArrivalDate).format("HH:mm")} <sup className="text-danger">{Number(difference) > 0 ? "+" + difference : difference}</sup> </> : <> {moment.utc(HeaderArrivalDate).format("HH:mm")}</>

// }

export const diffrenceInDatesCheck = (HeaderDepartureDate, HeaderArrivalDate) => {


  let currentTime = moment('23:59', "HH:mm");
  let startTime = moment(moment.utc(HeaderDepartureDate).locale('en').format("HH:mm"), "HH:mm");
  let endTime = moment(moment.utc(HeaderArrivalDate).locale('en').format("HH:mm"), "HH:mm");

  let newStartTime = moment.utc(HeaderDepartureDate).locale('en').format("ddd MMM DD, YYYY")
  let newEndTime = moment.utc(HeaderArrivalDate).locale('en').format("ddd MMM DD, YYYY")

  currentTime.toString();
  startTime.toString();
  endTime.toString();

  if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
    endTime.add(1, "days");       // handle spanning days endTime

    if (currentTime.hour() <= 12) {
      currentTime.add(1, "days");       // handle spanning days currentTime
    }
  }

  let isBetween = currentTime.isBetween(startTime, endTime);
  var msDiff = new Date(newEndTime).getTime() - new Date(newStartTime).getTime();    //Future date - current date
  var difference = Math.floor(msDiff / (1000 * 60 * 60 * 24));


  return Number(difference)



}

export const range = (end, start) => {


  var len = (end) - (start) + 1;
  var a = new Array(len);

  for (let i = 0; i < len; i++) a[i] = end - i;
  return a;
}

export const getFlightLogo = (data) => {
  if (data === "X1") {
    data = "HR";
  }

  let logoLowerCase = data && data.toLowerCase();
  let flightLogo = `https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/${logoLowerCase}.png`

  // if (logoLowerCase === "5z") {
  //   flightLogo = CemairAirlineLogo;
  // }
  // if (logoLowerCase === "4z") {
  //   flightLogo = AirlinkAirlineLogo;
  // }
  // if (logoLowerCase === "p0") {
  //   flightLogo = proFlightZambia;
  // }
  // if (logoLowerCase === "no") {
  //   flightLogo = NeosLogo
  // }
  // if (logoLowerCase === "q4") {
  //   flightLogo = Q4
  // }

  return flightLogo

}

export const moveIndex = (input, from) => {
  let newArr = []
  if (input && input.length > 0) {
    let index = input.findIndex(obj => obj.title === "PENALTIES")
    if (index > 0) {
      let ele = input.filter(obj => obj.title === "PENALTIES")[0]

      if (ele && ele !== null) {
        newArr = input.splice(0, 0, ele);
        return newArr

      } else {
        newArr = input
        return newArr
      }
    }


  }

}

export const getFlightArr = (arr) => {

  let newFlightArr = []

  arr &&
    arr.map((obj) => {
      Object.values(obj).map((data) => {
        newFlightArr = [...newFlightArr, ...data.flights];
      });
    });

  return newFlightArr

}


export const getFlightSrrData = (newFlightArr) => {

  let flightsArr = []

  newFlightArr.map((flightObj, index) => {

    flightsArr = [
      ...flightsArr,
      {
        from: flightObj.departureAirport,
        to: flightObj.arrivalAirport,
        flightNumber: flightObj && flightObj.number,
        meal: {},
        assistance: {},
        wheelChair: {},
        carrier: flightObj.carrier,
        code: flightObj.statusDetail && flightObj.statusDetail.code
      },
    ];
  });

  return flightsArr

}

export const splitFromCapital = (str) => {
  if (str === undefined) return
  let result = str.split(/(?=[A-Z])/);
  if (result.length === 1) {
    return str
  } else if (str === "Mixed Cabin") {
    return str
  } else {
    str = result.join(" ")
    return str

  }

}

export const DateZoneConvertion = (date) => {

  let resultDate = moment(date).tz("America/Toronto").format("DD MMM YYYY")

  return resultDate

}

export const TimeZoneConvertion = (date) => {

  let resultDate = moment(date).tz("America/Toronto").format("HH:mm")

  return resultDate

}


export const ticketsWarningMessage = (result) => {

  let warningMessages = result && result?.warnings;
  let ticketWarningMessageFilter = warningMessages &&
    warningMessages.filter(msg => msg?.message?.includes("Tickets might have been issued"))
  let ticketWarningMessage = ticketWarningMessageFilter &&
    ticketWarningMessageFilter?.length > 0 ? ticketWarningMessageFilter[0].message : "";

  return ticketWarningMessage

}

export const cancelledFlightCheck = (segmentsData) => {

  let flightCancelledCodeArr = []
  let flightCancelledCodeStatus = false;
  let cancelledFlightCode = ["HX", "UC", "UN", "NO", "WK"];
  let statusCode = ""
  segmentsData && segmentsData.forEach(data => {

    let flightsData = data && Object.values(data)[0]?.flights
    let cancelledFlightCheck = flightsData && flightsData.filter(flight => cancelledFlightCode.includes(flight?.statusDetail?.code))

    if (cancelledFlightCheck.length > 0) {
      flightCancelledCodeArr.push(cancelledFlightCheck)
      statusCode = cancelledFlightCheck && cancelledFlightCheck[0]?.statusDetail?.code
    }

  })

  if (flightCancelledCodeArr.length > 0) {
    flightCancelledCodeStatus = true
  }
  let result = {
    statusCode: statusCode,
    flightCancelledCodeStatus: flightCancelledCodeStatus
  }

  return result

}


export const cancelledFlightCode = (statusCode) => {
  let statusDescription = [
    {
      code: "HX",
      desc: "Have cancelled"
    },
    {
      code: "UC",
      desc: "Unable to confirm"
    },
    {
      code: "UN",
      desc: "Flight Cancelled by Airline."
    },
    {
      code: "NO",
      desc: "No action taken."
    },
    {
      code: "WK",
      desc: "WK"
    }

  ]

  let description = statusDescription.filter(flight => flight?.code === statusCode)
  let result = description && description.length > 0 ? description[0].desc : "";

  return result

}

export const dobAgeCheckValidation = (tripTypeFlowFrom, allData, tripData, dateOfBirth, paxType) => {

  let dobCheckFlag = false;
  let newDepartDate
  if (tripTypeFlowFrom == 'MyFiles') {
    if (allData?.bookingDetails?.itineraryFare?.applicableContract?.ageRule?.ageRestrictions?.applicableOn == "ARRIVAL") {
      let segmentLength = allData?.bookingDetails?.segments?.length - 1;
      newDepartDate = allData && allData.bookingDetails && allData.bookingDetails.segments && Object.values(allData.bookingDetails?.segments[segmentLength])[0]?.flights[0]?.arrivalDateTime
    } else {
      newDepartDate = allData?.departureDate
    }
  } else {
    newDepartDate = tripData?.departDate
  }

  let departureDateValue = newDepartDate ? newDepartDate : new Date()
  let formattedSecondreturnDate = moment(departureDateValue).format('DD-MM-YYYY')
  let formattedDOB = moment(dateOfBirth).format('DD-MM-YYYY')
  let startreturnDate = moment(formattedDOB, 'DD-MM-YYYY');
  let endreturnDate = moment(formattedSecondreturnDate, 'DD-MM-YYYY');
  let age = endreturnDate.diff(startreturnDate, 'years');

  let today = new Date();
  let dob = new Date(dateOfBirth);
  if (paxType === "Child" && (age >= 12 || age < 2)) {
    dobCheckFlag = true
  }
  if (paxType === "Adult" && age < 12) {
    dobCheckFlag = true
  }
  if (paxType == "InfantInLap" && age > 2) {
    dobCheckFlag = true
  }

  if (paxType == "InfantInLap" && (today < dob)) {
    dobCheckFlag = true
  }



  return dobCheckFlag

}


export const paxTypeCodeCheckResult = (faresPerTravellers) => {

  let paxTypeCodeArray = []
  let paxTypeCodeFlag = false;
  faresPerTravellers
    && faresPerTravellers.map(data => {
      let paxTypeCode = data && data.passengerDetails && data.passengerDetails.code
      if (!paxTypeCodeArray.includes(paxTypeCode)) {
        paxTypeCodeArray.push(paxTypeCode)
      }
    })
  let firstCase = ["ITX", "INN", "ITF"];
  let secondCase = ["ITX", "INN"];
  let thirdCase = ["ITX", "ITF"];
  let paxTypeCodeCaseFirst = paxTypeCodeArray.every(item => firstCase.indexOf(item) > -1)
  let paxTypeCodeCaseSecond = paxTypeCodeArray.every(item => secondCase.indexOf(item) > -1)
  let paxTypeCodeCaseThird = paxTypeCodeArray.every(item => thirdCase.indexOf(item) > -1)

  if (paxTypeCodeCaseFirst) {
    paxTypeCodeFlag = true
  }
  if (paxTypeCodeCaseSecond) {
    paxTypeCodeFlag = true
  }
  if (paxTypeCodeCaseThird) {
    paxTypeCodeFlag = true
  }

  return paxTypeCodeFlag;

}


export const maxMarkupAmountLimitPerPaxType = (PassengerWiseTransactionsDetails) => {

  let adultminimumMarkup;
  let childtminimumMarkup;
  let infantminimumMarkup;

  if (PassengerWiseTransactionsDetails && PassengerWiseTransactionsDetails.length > 0) {
    PassengerWiseTransactionsDetails.map(obj => {
      if (obj.paxType === PassengerType.ADULT) {
        let limit = obj.maxAgencyMarkupLimitToAvoidMerchantFee && obj.maxAgencyMarkupLimitToAvoidMerchantFee.amount
        adultminimumMarkup = Number(limit)
      }

      if (obj.paxType === PassengerType.CHILD) {
        let limit = obj.maxAgencyMarkupLimitToAvoidMerchantFee && obj.maxAgencyMarkupLimitToAvoidMerchantFee.amount
        childtminimumMarkup = Number(limit)
      }

      if (obj.paxType === PassengerType.INFANTINLAP) {
        let limit = obj.maxAgencyMarkupLimitToAvoidMerchantFee && obj.maxAgencyMarkupLimitToAvoidMerchantFee.amount
        infantminimumMarkup = Number(limit)
      }

    })
  }

  let maxLimit = {
    forAdultMaxLimit: adultminimumMarkup,
    forChildMaxLimit: childtminimumMarkup,
    forInfantMaxLimit: infantminimumMarkup
  }

  return maxLimit

}

export const flightCarriersData = (bookingItinenaries) => {
  let carrierArr = []
  let airlineMandatoryFlag = false;
  bookingItinenaries && bookingItinenaries?.forEach((val) => {
    Object?.values(val)?.forEach((data) => {
      data?.flights?.forEach((flight) => {
        carrierArr.push(flight.operatingCarrier)
      })
    })
  })

  if (carrierArr.includes("TK") || carrierArr.includes("RJ") || carrierArr.includes("CA")) {
    airlineMandatoryFlag = true
  } else {
    airlineMandatoryFlag = false
  }
  return airlineMandatoryFlag
}

export const flightCarriersDataPassportMandatory = (bookingItinenaries) => {
  let carrierArr = []
  let airlineMandatoryFlag = false;
  let bItineraries = bookingItinenaries && !Array.isArray(bookingItinenaries) ? [bookingItinenaries] : bookingItinenaries;
  bItineraries && bItineraries?.forEach((val) => {
    Object?.values(val)?.forEach((data) => {
      data?.flights?.forEach((flight) => {
        carrierArr.push(flight.operatingCarrier)
      })
    })
  })

  if (carrierArr.includes("RJ") || carrierArr.includes("MS") || carrierArr.includes("JD") || carrierArr.includes("KC" || carrierArr.includes("S7"))) {
    airlineMandatoryFlag = true
  } else {
    airlineMandatoryFlag = false
  }
  return airlineMandatoryFlag
}

export const airlineTransitionDetails = (faresPerTravellers, paymentRules, adultsCount, childCount, infantCount, markupValuePerPassenger) => {

  let adultMarkupValue = markupValuePerPassenger.adult;
  let childMarkupValue = markupValuePerPassenger.child;
  let infantMarkupValue = markupValuePerPassenger.infant;

  let paxTypeData = faresPerTravellers
  let amountInfo12 = []
  paxTypeData && paxTypeData.map((paxType, indexCount) => {

    let passengerDetails = paxType.passengerDetails;
    let type = passengerDetails && passengerDetails.type;
    let quantity;
    let totalTaxes = paxType.totalTaxes
    if (type === PassengerType.ADULT) {
      quantity = adultsCount
      totalTaxes = Number(totalTaxes.amount);
    }
    if (type === PassengerType.CHILD) {
      quantity = childCount
      totalTaxes = Number(totalTaxes.amount);
    }
    if (type === PassengerType.INFANTINLAP) {
      quantity = infantCount
      totalTaxes = Number(totalTaxes.amount);
    }
    let totalAmount = paxType.totalAmount
    let baseAmount = paxType.baseAmount
    let supplierBaseAmount = paxType.supplierBaseAmount
    let isPresent = amountInfo12.filter(obj => obj.type === type)

    if (isPresent.length === 0) {
      let passenger = {
        type: type,
        quantity: quantity,
        totalAmount: totalAmount.amount,
        baseAmount: baseAmount.amount,
        totalTaxes: totalTaxes,
        supplierBaseAmount: supplierBaseAmount && supplierBaseAmount.amount
      }
      amountInfo12.push(passenger)
    }

  })

  let sellingPassengerDetailsPerAdult = amountInfo12 && amountInfo12.filter(val => val.type === "Adult");
  let sellingTotalAmtForAdt = sellingPassengerDetailsPerAdult && sellingPassengerDetailsPerAdult.length > 0 ? sellingPassengerDetailsPerAdult[0]?.totalAmount : 0;
  sellingTotalAmtForAdt = Number(sellingTotalAmtForAdt) - Number(adultMarkupValue);

  let sellingPassengerDetailsPerChild = amountInfo12 && amountInfo12.filter(val => val.type === "Child");
  let sellingTotalAmtForChild = sellingPassengerDetailsPerChild && sellingPassengerDetailsPerChild.length > 0 ? sellingPassengerDetailsPerChild[0]?.totalAmount : 0;
  sellingTotalAmtForChild = Number(sellingTotalAmtForChild) - Number(childMarkupValue)

  let sellingPassengerDetailsPerInfant = amountInfo12 && amountInfo12.filter(val => val.type === "InfantInLap");
  let sellingTotalAmtForInfant = sellingPassengerDetailsPerInfant && sellingPassengerDetailsPerInfant.length > 0 ? sellingPassengerDetailsPerInfant[0]?.totalAmount : 0;
  sellingTotalAmtForInfant = Number(sellingTotalAmtForInfant) - Number(infantMarkupValue)

  let ccAllowed = paymentRules && paymentRules?.itineraryFare?.applicableContract?.cCAllowed;
  let ccMaxLimitForAirline = paymentRules && paymentRules?.itineraryFare?.applicableContract?.maxLimitToUseAirlinesMerchant;
  let ccMaxLimitForAirlinePerAdult = ccMaxLimitForAirline && ccMaxLimitForAirline?.amountPerAdult ? ccMaxLimitForAirline?.amountPerAdult?.amount : 0;
  let ccMaxLimitForAirlinePerChild = ccMaxLimitForAirline && ccMaxLimitForAirline?.amountPerChild ? ccMaxLimitForAirline?.amountPerChild?.amount : 0;
  let ccMaxLimitForAirlinePerInfant = ccMaxLimitForAirline && ccMaxLimitForAirline?.amountPerInfantInLap ? ccMaxLimitForAirline?.amountPerInfantInLap?.amount : 0;
  let totalCCMaxLimitForAirline = Number(ccMaxLimitForAirlinePerAdult) + Number(ccMaxLimitForAirlinePerChild) + Number(ccMaxLimitForAirlinePerInfant);

  // let totalSellingAmount = paymentRules && paymentRules?.itineraryFare?.totalAmount?.amount - Number(adultsMarkup) - Number(childMarup) - Number(infantMarkup);
  let transactionDetails = paymentRules && paymentRules?.transactionsDetails;
  let transactionDetailsPerPassenger = paymentRules && paymentRules?.PassengerWiseTransactionsDetails;
  // TRANSITION DETAILS FOR ADULT 
  let transactionDetailsForAdult = transactionDetailsPerPassenger && transactionDetailsPerPassenger.filter(val => val.paxType === "Adult")
  let airlineTransactionDetailsForAdt = transactionDetailsForAdult && transactionDetailsForAdult.length > 0 && transactionDetailsForAdult[0]?.transactionsDetails.filter(tranDetail => tranDetail.merchant === "AirLine");
  let airlineTransactionTotalAmtForAdult = airlineTransactionDetailsForAdt && airlineTransactionDetailsForAdt.length > 0 && airlineTransactionDetailsForAdt[0]?.totalAmount?.amount;

  // TRANSITION DETAILS FOR CHILD 
  let transactionDetailsForChild = transactionDetailsPerPassenger && transactionDetailsPerPassenger.filter(val => val.paxType === "Child")
  let airlineTransactionDetailsForChild = transactionDetailsForChild && transactionDetailsForChild.length > 0 && transactionDetailsForChild[0]?.transactionsDetails.filter(tranDetail => tranDetail.merchant === "AirLine");
  let airlineTransactionTotalAmtForChild = airlineTransactionDetailsForChild && airlineTransactionDetailsForChild.length > 0 && airlineTransactionDetailsForChild[0]?.totalAmount?.amount;

  // TRANSITION DETAILS FOR INFANT 
  let transactionDetailsForInfant = transactionDetailsPerPassenger && transactionDetailsPerPassenger.filter(val => val.paxType === "InfantInLap")
  let airlineTransactionDetailsForInfant = transactionDetailsForInfant && transactionDetailsForInfant.length > 0 && transactionDetailsForInfant[0]?.transactionsDetails.filter(tranDetail => tranDetail.merchant === "AirLine");
  let airlineTransactionTotalAmtForInfant = airlineTransactionDetailsForInfant && airlineTransactionDetailsForInfant.length > 0 && airlineTransactionDetailsForInfant[0]?.totalAmount?.amount;

  let airlineTransactionCheck = transactionDetails && transactionDetails.filter(val => val.merchant === "AirLine");
  let airlineTransactionAmount = airlineTransactionCheck && airlineTransactionCheck.length > 0 ? airlineTransactionCheck[0].totalAmount.amount : 0;
  let markupLimitAmountPerAdult = 0;
  let markupLimitAmountPerChild = 0;
  let markupLimitAmountPerInfant = 0;

  markupLimitAmountPerAdult = Math.floor(Number(ccMaxLimitForAirlinePerAdult) - Number(sellingTotalAmtForAdt));
  markupLimitAmountPerChild = Math.floor(Number(ccMaxLimitForAirlinePerChild) - Number(sellingTotalAmtForChild));
  markupLimitAmountPerInfant = Math.floor(Number(ccMaxLimitForAirlinePerInfant) - Number(sellingTotalAmtForInfant));
  markupLimitAmountPerAdult = markupLimitAmountPerAdult < 0 ? 0 : markupLimitAmountPerAdult;
  markupLimitAmountPerChild = markupLimitAmountPerChild < 0 ? 0 : markupLimitAmountPerChild;
  markupLimitAmountPerInfant = markupLimitAmountPerInfant < 0 ? 0 : markupLimitAmountPerInfant;

  let transitionFlag = true;
  if ((infantCount > 0 && markupLimitAmountPerInfant == 0) && transactionDetails?.length > 1) {
    transitionFlag = false;
  }

  let markupLimit = {
    adult: markupLimitAmountPerAdult,
    child: markupLimitAmountPerChild,
    infant: markupLimitAmountPerInfant,
    ccAllowed: ccAllowed,
    airlineTransactionAmount: airlineTransactionAmount,
    transitionVerbiageFlag: transitionFlag
  }

  return markupLimit


}

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const extractTime12Hr=(dateTimeStr)=> {
  const dateTime = new Date(dateTimeStr);
  let hours = dateTime.getHours();
  let minutes = dateTime.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  
  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;
}


export const  timeConvert = (n) => {
  let number = n;
  let hours = (number / 60);
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
 
  return number = rhours + " hr " + rminutes + " min";
}

export const getSegmentData = (segment) => {

  const layover = segment?.layovers?.length ? `${segment?.layovers?.length} Stops` : "Non-stop";
  const firstFlight = segment?.flights?.[0];
  const lastFlight = segment?.flights?.[segment?.flights?.length - 1];
  const duration = calculateDuration(firstFlight?.departureDateTime, lastFlight?.arrivalDateTime);
  const cabin = !segment?.cabin || typeof segment?.cabin === "string" ? segment?.cabin : segment?.cabin.find(cabin => cabin.flightIds.includes(firstFlight.id))?.type;
  const fareType = !segment?.brand || typeof segment?.brand === "string" ? segment?.brand : segment?.brand.find(brand => brand.flightIds.includes(firstFlight.id))?.brandName;
  return {
      airlineLogo: `${firstFlight.carrier.toLowerCase()}.png`,
      airline: firstFlight.carrierName,
      departureAirportCode: firstFlight.departureAirport,
      departureCity: firstFlight.departureAirportCity,
      arrivalAirportCode: lastFlight.arrivalAirport,
      arrivalCity: lastFlight.arrivalAirportCity,
      departure: formatDateTime(firstFlight.departureDateTime),
      arrival: formatDateTime(lastFlight.arrivalDateTime),
      duration: duration,
      operatedBy: firstFlight.operatingCarrierName,
      flightNumber: firstFlight.number,
      cabin: cabin,
      fareType: fareType,
      layover: layover
  }

}

const calculateDuration = (departure, arrival) => {
  const departureTime = new Date(departure);
  const arrivalTime = new Date(arrival);
  const durationInMinutes = (arrivalTime.getTime() - departureTime.getTime()) / (1000 * 60);

  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return `${hours}h ${minutes}m`;
};

const formatDateTime = (dateTime) => {
  return moment(dateTime).format('hh:mm A MMMM, DD');
};