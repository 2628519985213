import React, { useState, useImperativeHandle, forwardRef, useEffect, memo, useCallback, useMemo } from 'react';
import { Grid, Typography, TextField, FormControl, Box, Autocomplete } from '@mui/material';
// @ts-ignore
import IconShield from '../../../assets/icons/Icon-Shield.svg';
// @ts-ignore
import AmericaCard from '../../../assets/icons/AmericaCard.svg';
// @ts-ignore
import MasterCard from '../../../assets/icons/masterCard.svg';
// @ts-ignore 
import VisaCard from '../../../assets/icons/VisaCard.svg';
// @ts-ignore 
import IconCreditCard from '../../../assets/icons/IconCreditCard.svg';
// @ts-ignore 
import trustmark from '../../../assets/icons/trustmark.svg';

import valid from 'card-validator';
import { debounce } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

export interface PaymentDetails {
  nameOnCard: string;
  number: string;
  cvv: string;
  expiryDate: string;
  streetAddress: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  cardType: string;
}

interface PaymentComponentProps {
  countriesData: any;
  stateAPI: any;
  stateData: any;
  isFormValid: boolean;
  setIsFormValid: any;
  showInvalidErrorMessage: any,
  showCC: boolean;
}

export interface PaymentComponentHandle {
  getPaymentDetails: () => PaymentDetails;
}

const PaymentComponent = memo(forwardRef<PaymentComponentHandle, PaymentComponentProps>(({ countriesData, stateAPI, stateData = [], isFormValid, setIsFormValid, showInvalidErrorMessage, showCC }, ref) => {
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    nameOnCard: '',
    number: '',
    cvv: '',
    expiryDate: '',
    streetAddress: '',
    country: '',
    city: '',
    province: '',
    postalCode: '',
    cardType:""
  });

  const [cardErrors, setCardErrors] = useState({
    nameOnCard: '',
    number: '',
    expiryDate: '',
    cvv: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    country: '',
    province:''
  });

  const [cityData, setCityData] = useState<String[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [filteredCities, setFilteredCities] = useState<string[]>([]);
  const debouncedStateAPI = useCallback(debounce(stateAPI, 500), []);
  const MAX_DISPLAYED_CITIES = 20;

  const debouncedFilterCities = useMemo(
      () =>
        debounce((input: string) => {
          if (input) {
            const matchedCities = cityData
              .filter((city) => city.toLowerCase().includes(input.toLowerCase()))
              .slice(0, MAX_DISPLAYED_CITIES);
            setFilteredCities(matchedCities as string[]);
          } else {
            setFilteredCities([]);
          }
        }, 300),
      [cityData]
    );


  useEffect(() => {
    if (paymentDetails.country) {
      debouncedStateAPI(paymentDetails.country);
    }
  }, [paymentDetails.country]);

  useEffect(() => {
    if (countriesData && paymentDetails.country) {
      let country = countriesData.find((country: any) => country.country === paymentDetails.country);
      setCityData(country.cities);
    }
  }, [countriesData])

  useEffect(() => {
    if (showInvalidErrorMessage || isFormValid) {
      validateCardDetails()
    }
  }, [showInvalidErrorMessage, isFormValid])

  useImperativeHandle(ref, () => ({
    getPaymentDetails: () => {
      return paymentDetails;
    }
  }));

  const handleInputChange = (e: any, newInputValue?: string) => {
    const { name, value } = e.target;
    setIsFormValid(false)
    if (name === 'number' && /[^0-9\s]/.test(value)) {
      setCardErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Please enter valid numbers"
      }));
      return;
    }
    if (name === 'cvv' && /[^0-9]/.test(value)) {
      setCardErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Please enter valid numbers"
      }));
      return;
    }
    if (name === 'expiryDate') {
      const cleanValue = value.replace(/\D/g, ''); // Remove non-digit characters
      if (cleanValue.length > 6) {
          setCardErrors((prevErrors: any) => ({
              ...prevErrors,
              [name]: "Please enter a valid expiry date (MM/YYYY)",
          }));
          return;
      }
      const formattedValue =
          cleanValue.length > 2
              ? cleanValue.substring(0, 2) + '/' + cleanValue.substring(2)
              : cleanValue;
      setPaymentDetails((prevDetails: any) => ({
          ...prevDetails,
          [name]: formattedValue,
      }));
      setCardErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: "",
      }));
      return;
  }
    setPaymentDetails({ ...paymentDetails, [name]: value });
    if (name === 'city') {
      setInputValue(newInputValue);
      // debouncedFilterCities(newInputValue);
    }
    setCardErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: ""
    }));
  };

  //Payment details validator
  const validateCardDetails = (value: any = null) => {
    const supportedCardTypes = ['Visa', 'MasterCard', 'American Express','Mastercard'];
    const datePatternRegEx = /^(0[1-9]|1[0-2])\/?([0-9]{4})$/;
    let error: any = {};
    let ccObj = {
      number: valid.number(paymentDetails.number),
      nameOnCard: valid.cardholderName(paymentDetails.nameOnCard),
      expiryDate: valid.expirationDate(paymentDetails.expiryDate),
      cvv: valid.cvv(paymentDetails.cvv),
    }
    let addressObj = {
      streetAddress: paymentDetails.streetAddress ? { isValid: true } : { isValid: false },
      city: paymentDetails.city ? { isValid: true } : { isValid: false },
      country: paymentDetails.country ? { isValid: true } : { isValid: false },
      province: paymentDetails.province ? { isValid: true } : { isValid: false },
      postalCode: paymentDetails.postalCode ? { isValid: true } : { isValid: false },
    }
    let cardValidators: any = addressObj;
    if(showCC){
      cardValidators = {...ccObj,...cardValidators}
    }

    let ccObjErrorMessage = {
      nameOnCard: 'Name',
      number: 'Card Number',
      expiryDate: !paymentDetails.expiryDate ? 'Expiry Date' : !datePatternRegEx.test(paymentDetails.expiryDate) ? 'date format. Please use MM/YYYY format.' : 'Expiry Date',
      cvv: 'CVV Code',
    }

    const getErrorMessage = (key: string): string => {
        let errorMessages: Record<string, string> = {
            streetAddress: 'Street Address',
            city: 'City',
            country: 'Country',
            province: 'Province',
            postalCode: 'Postal Code'
        };
      errorMessages = showCC ? {...ccObjErrorMessage,...errorMessages} : errorMessages;
      return errorMessages[key] ? `${t('Invalid')} ${t(errorMessages[key])}` : '';
    };

    Object.keys(cardValidators).forEach((key: any) => {
        if (!cardValidators[key]?.isValid) {
            setIsFormValid(false);
            error[key] = getErrorMessage(key);
        }
    });

    // Validate card type
    if (showCC && cardValidators.number.isValid) {
        const cardType: string = cardValidators.number.card?.niceType;
        let cardTypes = cardType.toLowerCase() == "visa" ? "VI" : cardType.toLowerCase() == "mastercard" ? "CA" : cardType.toLowerCase() == "american express" ? "AX" : "";
        setPaymentDetails({ ...paymentDetails, cardType: cardTypes });
        if (cardType && !supportedCardTypes.includes(cardType)) {
            setIsFormValid(false);
            error["number"] = `${t('Invalid card type')}: ${cardType}. ${t('Only Visa, MasterCard, and American Express are accepted')}`;
        }
    }

    setCardErrors(error);
};
  

  const handleCountryChange = (event: any, value: any) => {
    if (value) {
      setPaymentDetails({...paymentDetails, country: value.country})
      setCityData(value.cities);
      stateAPI(value.country);
      setCardErrors((prevErrors: any) => ({
        ...prevErrors,
        country: ""
      }));
    }else{
      setPaymentDetails({ ...paymentDetails, country: '', city: '', province: '' })
    }
  };

  const handleStateChange = (event: any, value: any) => {
    setPaymentDetails({ ...paymentDetails, province: value ? value.name : '' });
    setCardErrors((prevErrors: any) => ({
      ...prevErrors,
      province: ""
    }));
  };

  const formatCardNumber = (number: string) => {
    const cleanNumber = number.replace(/\s+/g, '');
    const cardValidation = valid.number(cleanNumber);
    const gaps = cardValidation.card?.gaps || [];
    let formattedNumber = '';
    let lastGapIndex = 0;
    gaps.forEach((gap) => {
      if (cleanNumber.length > lastGapIndex) {
        formattedNumber += cleanNumber.substring(lastGapIndex, gap) + ' ';
        lastGapIndex = gap;
      }
    });
    formattedNumber += cleanNumber.substring(lastGapIndex);
  
    return formattedNumber.trim();
  };

  const formatExpiryDateFullYear = (expiry: string) => {
    const cleanExpiry = expiry.replace(/\D/g, ''); // Removes anything that's not a digit
    let formattedExpiry = '';
    if (cleanExpiry.length > 2) {
      formattedExpiry = cleanExpiry.substring(0, 2) + '/' + cleanExpiry.substring(2, 6);
    } else {
      formattedExpiry = cleanExpiry;
    }
    return formattedExpiry;
  };
  
  return (
    <>
    {showCC ? <div>
      <Box className="Booking_paymentHeader">
        <Typography variant="h5" className='Booking_paymentTitle'>
          <img src={IconCreditCard} alt="Icon Credit Card" /> {t('Payment Details')}
        </Typography>
        <img src={trustmark} className='trustmark' alt="Trustmark" />
      </Box>
      <Typography variant="body1" className='Booking_paymentMsg'>
        <img src={IconShield} alt="Icon Shield" /> {t('We Use Secure Transmission & Protect Your Personal Information')}
      </Typography>
      <Typography variant="body1" className='Booking_paymentSubTitle'>
        {t('We accept all major credit cards')}
      </Typography>
      <Box className='Booking_paymentCards'>
        <img src={VisaCard} className='cardsImg' alt="Visa Card" />
        <img src={AmericaCard} className='cardsImg' alt="American Card" />
        <img src={MasterCard} className='cardsImg' alt="Master Card" />
      </Box>
      </div> : null}
      <Grid>
      {showCC ? <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Name On Card")}
            name="nameOnCard"
            value={paymentDetails.nameOnCard}
            onChange={handleInputChange}
            variant="standard"
            required
            className='Booking_Label'
            error={cardErrors.nameOnCard ? true : false}
            helperText={cardErrors.nameOnCard}
            // onBlur={() => validateCardDetails("nameOnCard")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('Credit Card Number')}
            name="number"
            value={formatCardNumber(paymentDetails.number)}
            onChange={handleInputChange}
            variant="standard"
            className='Booking_Label'
            required
            error={cardErrors.number ? true : false}
            helperText={cardErrors.number}
            // onBlur={() => validateCardDetails("number")}
            inputProps={{
              pattern: "[0-9]*",
              inputMode: "numeric"
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("CVV Code")}
            name="cvv"
            value={paymentDetails.cvv}
            onChange={handleInputChange}
            variant="standard"
            className='Booking_Label'
            required
            error={cardErrors.cvv ? true : false}
            helperText={cardErrors.cvv}
            // onBlur={() => validateCardDetails("cvv")}
            inputProps={{
              pattern: "[0-9]*",
              inputMode: "numeric"
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('Expiry Date')}
            name="expiryDate"
            value={formatExpiryDateFullYear(paymentDetails.expiryDate)}
            onChange={handleInputChange}
            variant="standard"
            required
            className='Booking_Label'
            error={cardErrors.expiryDate ? true : false}
            helperText={cardErrors.expiryDate}
            // onBlur={() => validateCardDetails("expiryDate")}
            inputProps={{
              maxLength: 7,
              minLength: 7,
              pattern: "[0-9]*",
              inputMode: "numeric"
            }}
          />
        </Grid>
        </Grid> : null}
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" className='Booking_Traveller' >
          {t('Billing Address')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Street Name And House No")}
            name="streetAddress"
            value={paymentDetails.streetAddress}
            onChange={handleInputChange}
            variant="standard"
            required
            error={cardErrors.streetAddress ? true : false}
            helperText={cardErrors.streetAddress}
            className="Booking_Label"
            // onBlur={() => validateCardDetails("streetAddress")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <Autocomplete
            fullWidth
            options={filteredCities?.length ? filteredCities : cityData}
            getOptionLabel={(option: string) => option}
            value={
              cityData
                ? cityData.find((country: any) => country === paymentDetails.city) || ''
                : ''
            }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue); // newInputValue is correctly typed as string
              debouncedFilterCities(newInputValue);
            }}
            onChange={(event, newValue) => {
              const value = typeof newValue === 'string' ? newValue : ''; // Ensure value is a string
              setInputValue(value);
              setPaymentDetails({ ...paymentDetails, city: value });
              setCardErrors((prevErrors) => ({
                ...prevErrors,
                city: value ? '' : t('Please select a valid city'),
              }));
            }}
            onBlur={() => {
              if (!filteredCities.includes(inputValue)) {
                setCardErrors((prevErrors) => ({
                  ...prevErrors,
                  city: t('Please select a valid city'),
                }));
                setInputValue('');
              } else {
                setCardErrors((prevErrors) => ({ ...prevErrors, city: '' }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('City')}
                name="city"
                variant="standard"
                required
                error={!!cardErrors.city}
                helperText={cardErrors.city}
                // onBlur={() => validateCardDetails('city')}
              />
            )}
            renderOption={(props, option, { index }) => (
              <li {...props} key={`${option}-${index}`}>
                {option}
              </li>
            )}
          /> */}
          <TextField
            fullWidth
            label={t("City")}
            name="city"
            value={paymentDetails.city}
            onChange={handleInputChange}
            variant="standard"
            required
            error={cardErrors.city ? true : false}
            helperText={cardErrors.city}
            className="Booking_Label"
            // onBlur={() => validateCardDetails("streetAddress")}
          />

        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            options={cityData}
            getOptionLabel={(option: string) => option}
            value={paymentDetails.city || ''}
            onChange={(event, newValue) => {
              handleInputChange({ target: { name: 'city', value: newValue || '' } });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                name="city"
                variant="standard"
                required
                error={cardErrors.city ? true : false}
                helperText={cardErrors.city}
                className="Booking_Label"
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={countriesData}
            getOptionLabel={(option) => option.country}
            onChange={handleCountryChange}
            id="country-info"
            value={countriesData ? countriesData.find((country: any) => country.country === paymentDetails.country) || null : null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Country')}
                name="country"
                variant="standard"
                required
                error={cardErrors.country ? true : false}
                helperText={cardErrors.country}
                className='Booking_Label'
                // onBlur={() => validateCardDetails("country")}

              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="standard" required disabled={!paymentDetails.country}>
          <Autocomplete
              options={stateData?.states || []}
              getOptionLabel={(option: any) => `${option.name}`}
              onChange={handleStateChange}
              id="state-info"
              value={stateData?.states ? stateData.states.find((state: any) => state.name === paymentDetails.province) || null : null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t('State')} / ${t('Province')}`}
                variant="standard"
                name="province"
                required
                error={cardErrors.province ? true : false}
                helperText={cardErrors.province}
                className='Booking_Label'
                // onBlur={() => validateCardDetails("province")}
              />
            )}
          />
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={t("Postal Code")}
            name="postalCode"
            value={paymentDetails.postalCode}
            onChange={handleInputChange}
            variant="standard"
            required
            error={cardErrors.postalCode ? true : false}
            helperText={cardErrors.postalCode}
            className="Booking_Label"
            // onBlur={() => validateCardDetails("postalCode")}
          />
        </Grid>
        </Grid>
      </Grid>
    </>
  );
}));

export default PaymentComponent;