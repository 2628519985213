export const FrenchErrorMessages = {
    "Please select valid Origin / Destination": "Veuillez sélectionner une Origine/Destination Valide.",
    "Please select valid dates": "Veuillez sélectionner des dates valides.",
    "Please select valid age": "Veuillez sélectionner un Âge Valide.",
    "Please select number of infants": "Veuillez sélectionner un nombre de bébés plus petit ou égal au nombre d'adulte.",
    "price is not available": "Nous sommes désolés, mais ce tarif n'est actuellement pas disponible pour la réservation. Veuillez essayer de sélectionner un autre tarif. You will be redirected to Search Page. Please try again.",
    "Technical error occur. Please contact us to make this booking": "Une erreur technique est survenue. Veuillez nous contacter pour effectuer cette réservation.",
    "Please fill all the required fields": "Veuillez remplir tous les champs obligatoires",
    "Please fill out required information in SearchBox section": "Veuillez remplir les informations requises dans la section Boîte de Recherche. ",
    "Please fill out your first and last names along with any other required information in the Traveller section before selecting Pay Monthly": "	Veuillez remplir vos prénom et nom ainsi que toute autre information requise dans la section Voyageur avant de sélectionner Paiement Mensuel. ",
    "No search result found for your search. Please change some parameters and try again": "Aucun résultat trouvé pour votre recherche. Veuillez modifier certains paramètres et réessayer. ",
    "A technical error prevents us from booking": "Une erreur technique nous empêche de réserver.",
    "There is no response from the pricing API. You will be redirected to the search page. Please try again.": "Il n'y a pas de réponse de l'API de tarification. Vous serez redirigé vers la page de recherche. Veuillez réessayer.",
    "Please fill all required fields for Uplift Payment": "Veuillez remplir tous les champs requis pour le Paiement Uplift.",
    "Please select a valid city": "Veuillez sélectionner une Ville Valide",
    "Invalid card type": "Type de carte invalide",
    "Only Visa, MasterCard, and American Express are accepted": "Seules les cartes Visa, MasterCard et American Express sont acceptées.",
    "Please Enter Valid Promo code.": "Veuillez Entrer un Code Promo Valide",
    "You have an insufficient balance": "Vous avez un Solde Insuffisant.",
    "Branded Fares Error": "Erreur des tarifs négociés",
    "No Search Result Found": "Aucun Résultat Trouvé"
}